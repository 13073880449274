/*eslint-disable*/
export const attrs = [
  // 页面底部
  'other_bottom_image', // 底部logo
  'other_bottom_code', // 底部股票代码
  'other_bottom_phone_name1', // 电话1名称
  'other_bottom_phone_value1', // 电话1值
  'other_bottom_phone_name2', // 电话2名称
  'other_bottom_phone_value2', // 电话2值
  'other_bottom_phone_name3', // 电话3名称
  'other_bottom_phone_value3', // 电话3值
  'other_bottom_line_title', // 横线下标题
  'other_bottom_microblog_image', // 微博二维码
  'other_bottom_official_image', // 公众号二维码
  'other_bottom_copyright', // 版权信息
  'other_bottom_filings', // 备案信息
  'other_bottom_law', // 法律声明
  'other_bottom_privacy', // 隐私条款

  'other_bottom_phone_name1', // 隐私条款
  'other_bottom_phone_value1', // 隐私条款
  'other_bottom_phone_value2', // 隐私条款
  'other_bottom_phone_value3', // 隐私条款
  'other_bottom_phone_value4', // 隐私条款
  'other_bottom_email_name1', // 隐私条款
  'other_bottom_email_value1', // 隐私条款
];
