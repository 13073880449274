<!--eslint-disable-->
<template>
  <div class="components-news">
    <div class="components-news-title">
      <div class="content-title1">{{attrDatas['information_news_title']}}</div>
      <div class="content-title2">{{attrDatas['information_news_desc']}}</div>
    </div>
    <div class="components-news-content">
      <NoData v-if="!(tableData && tableData.length)"/>
      <div class="news-container" v-if="tableData && tableData.length">
        <div
          class="news-container-item animate__animated animate__flipInY"
          v-for="table in tableData"
          @mouseover="newsHover = table.id"
          @mouseout="newsHover = null"
        >
          <div class="news-item-card" @click="onDetail(table)">
            <div class="news-item-card-logo">
              <img :src="`${viewPath}${table.imageFilePath}`" />
            </div>
            <div class="news-item-card-desc">
              <div class="news-title" style="
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;">{{table.newsTitle}}</div>
              <div class="news-date">{{DateFormat(table.publishTime, 'yyyy-MM-dd')}}</div>
            </div>
            <img
              class="news-item-card-go"
              :src="newsHover === table.id ? newsPng2 : newsPng1"
            />
          </div>
        </div>
      </div>
      <div class="news-container-page" v-if="tableData && tableData.length">
        <el-pagination
            background
            :current-page="searchData.pageNum"
            :page-size="searchData.pageSize"
            :total="totalElement"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, pager, prev, jumper, next">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { DateFormat, getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "@/view/News/attrNames";
import { pageNews } from "@/assets/api/apiService";
import newsPng1 from '@/assets/images/home/news-more.png';
import newsPng2 from '@/assets/images/home/service/MORE1.png';
import NoData from "@/components/NoData";
import pubPath from "@/libs/path";

export default {
  components: {
    NoData
  },
  data () {
    return {
      newsPng1: newsPng1,
      newsPng2: newsPng2,
      newsHover: null,
      viewPath: pubPath.imagePath,
      tableData: [],
      searchData: {
        pageNum: 1,
        pageSize: 20
      },
      totalElement: 0,
      lang_str: getStorage('soling_language', false) || navigator.language.substring(0, 2)
    }
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  watch: {
    language (val) {
      this.lang_str = val;
      this.getPageNews();
    }
  },
  mounted () {
    this.getPageNews();
  },
  methods: {
    DateFormat,
    async getPageNews () {
      const types = {zh: 1, en: 2, ja: 3};
      let res = await pageNews({
        ...this.searchData,
        type: 1,
        publishChannel: types[this.lang_str]
      });
      this.tableData = res.data.content;
      this.totalElement = res.data.totalElements || 0;
    },
    handleSizeChange(val) {
      this.searchData.pageSize = val;
      this.getPageNews();
    },
    handleCurrentChange(val) {
      this.searchData.pageNum = val;
      this.getPageNews();
    },
    onDetail (data) {
      this.$router.push({name: 'News', query: {type: this.$route.query.type, id: data.id}});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
