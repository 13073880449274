<!--eslint-disable-->
<template>
  <div class="layout-main">
    <Icon v-if="!isMobile"/>
    <Header></Header>
    <div class="main-container">
      <transition enter-active-class="animate__animated">
        <router-view></router-view>
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import Header from '@/layout/components/Header/index.vue';
import Footer from '@/layout/components/Footer/index.vue';
import Icon from '@/layout/components/Icon/index.vue';
import {getMenuTree, findContentConfig} from "@/assets/api/apiService";
import { mapState } from "vuex";
import { getStorage, rem } from "@/libs/tool";
import localStr from "@/libs/local";
export default {
  name: 'layoutMain',
  components: {
    Icon,
    Header,
    Footer
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language
    }),
    docTitle () {
      const localTitle = {zh: 'docTitle_zh', en: 'docTitle_en', ja: 'docTitle_jp'};
      const localMenu = {zh: 'menuNameCn', en: 'menuNameEn', ja: 'menuNameJpn'};
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      if (!res[localMenu[this.language]]) return localStr[localTitle[this.language]];
      let title = null;
      if (res.child) {
        const childRes = res.child.find(it => it.childIndex === +this.$route.query.type) || {};
        const childMenu = childRes[localMenu[this.language]];
        title = `${childMenu ? childMenu + ' ' : ''}` + (res[localMenu[this.language]] || '') + ' | ' + localStr[localTitle[this.language]];
      }
      return title;
    },
  },
  metaInfo() {
    // 在这里返回动态的meta信息
    return {
      title: this.docTitle,
      meta: [
        { name: 'description', content: '首页 Soling | SOLING 索菱股份有限公司官网 领先的智能网联生态合作伙伴' },
        { name: 'keywords', content: '索菱 智能网联 自动驾驶 智能座舱 车联网' },
        // 其他 meta 标签
      ],
    };
  },
  mounted () {
    this.rem();
    window.addEventListener('resize', this.checkIfMobile); // 监听窗口大小变化
    this.getMenuTree();
    let language = getStorage('soling_language', false) || navigator.language.substring(0, 2);
    this.getContentConfig(language);
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
  },
  watch: {
    language (val) {
      this.getContentConfig(val);
    }
  },
  data () {
    return {}
  },
  methods: {
    rem,
    checkIfMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      let isMobile = /iphone|ipad|ipod|android|webos|blackberry|windows phone|iemobile|wpdesktop/i.test(userAgent);
      if (!isMobile) isMobile = window.innerWidth < 1024;
      this.$store.dispatch('setIsMobile', isMobile);
    },
    // 获取菜单树
    async getMenuTree () {
      let res = await getMenuTree({type: 2});
      const temp = res.data;
      const menu = temp.filter(t => t.webUrl);
      menu.forEach(it => {
        if (it.child) {
          it.child = it.child.map((c, index) => {
            return {
              ...c,
              childIndex: index + 1
            }
          })
        }
      })
      await this.$store.dispatch('getMenuTreeData', menu);
    },
    // 获取所有配置
    async getContentConfig (lang) {
      const types = {zh: 1, en: 2, ja: 3};
      let res = await findContentConfig({languageType: types[lang], isPublic: 1});
      await this.$store.dispatch('setContentConfig', res.data);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkIfMobile); // 移除事件监听器
    },
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
