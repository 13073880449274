<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="footer-main">
      <div class="footer-main-title">
        <div class="footer-logo-img">
          <img v-if="!attrDatas['other_bottom_image']" src="../../../assets/images/footer/footer-logo.png"/>
          <img v-if="attrDatas['other_bottom_image']" :src="attrDatas['other_bottom_image']"/>
        </div>
        <div class="stock">{{attrDatas['other_bottom_code']}}</div>
      </div>
      <div class="footer-main-content">
        <div class="footer-main-content-menu">
          <div class="footer-main-content-menu-item" v-for="menu in treeData">
            <div class="footer-menu-item-title" @click="onJump(menu.webUrl)">{{menu[`menuName${attributeLang}`]}}</div>
            <div class="footer-menu-item-sub-text" v-for="c in menu.child">
              <a href="#" @click="onJump(menu.webUrl, c.childIndex)">{{c[`menuName${attributeLang}`]}}</a>
            </div>
          </div>
        </div>
        <div class="footer-main-content-info">
          <div class="footer-main-content-info-tel">
            <div class="footer-main-content-info-item" style="width: 66%;">
              <div class="footer-info-item-title">{{attrDatas['other_bottom_phone_name1']}}</div>
              <div class="footer-info-item-sub-phone">
                <div class="footer-info-item-sub-text width1">{{attrDatas['other_bottom_phone_value1']}}</div>
                <div class="footer-info-item-sub-text width2">{{attrDatas['other_bottom_phone_value2']}}</div>
                <div class="footer-info-item-sub-text width1">{{attrDatas['other_bottom_phone_value3']}}</div>
                <div class="footer-info-item-sub-text width2">{{attrDatas['other_bottom_phone_value4']}}</div>
              </div>
            </div>
            <div class="footer-main-content-info-item" style="width: 34%;padding-left: 5px;">
              <div class="footer-info-item-title">{{attrDatas['other_bottom_email_name1']}}</div>
              <div class="footer-info-item-sub-text">{{attrDatas['other_bottom_email_value1']}}</div>
            </div>
          </div>

          <div class="footer-main-content-info-code">
            <div class="footer-code-about">
              {{attrDatas['other_bottom_line_title']}}
            </div>
            <div class="footer-code-icon">
              <el-popover
                popper-class="footer_popover"
                placement="bottom"
                trigger="hover">
                <img class="footer_code" :src="attrDatas['other_bottom_microblog_image']"/>
                <img slot="reference" style="margin-right: 20px;" src="../../../assets/images/footer/weibo.svg"/>
              </el-popover>
              <el-popover
                popper-class="footer_popover"
                placement="bottom"
                trigger="hover">
                <img class="footer_code" :src="attrDatas['other_bottom_official_image']"/>
                <img slot="reference" src="../../../assets/images/footer/weixin.svg"/>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-record-number">
        <div class="footer-record-number-left" @click="toFilings">
          {{attrDatas['other_bottom_copyright']}} {{attrDatas['other_bottom_filings']}}
        </div>
        <div class="footer-record-number-right">
          <a class="footer-right-content" @click="goLaw">{{attrDatas['other_bottom_law']}}</a>
          <a @click="goPrivacy">{{attrDatas['other_bottom_privacy']}}</a>
        </div>
      </div>
    </div>
    <MFooter v-else></MFooter>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "./attrNames";
import MFooter from "@/layout/components/Footer/m_footer";

export default {
  name: 'Footer',
  components: {
    MFooter
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    treeData () {
      return this.menuTree.filter(menu => menu.webUrl !== 'Home');
      // if (this.language === 'zh') {
      //   return this.menuTree.filter(menu => menu.webUrl !== 'Home');
      // } else {
      //   return this.menuTree.filter(menu => menu.webUrl !== 'Home').slice(0, this.menuTree.length - 2);
      // }
    },
    menuData () {
      return this.menuTree.filter(menu => menu.webUrl !== 'Home');
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
  },
  data() {
    return {};
  },
  methods: {
    onJump (name, type) {
      if (this.$route.name !== name) {
        this.$router.push({ name: name, query: {type: type} });
      } else {
        this.$router.replace({ name: name, query: {type: type}, params: { key: Date.now() } });
      }
    },
    toFilings () {
      window.open('https://beian.miit.gov.cn/');
    },
    goLaw () {
      window.open('/law.html');
    },
    goPrivacy () {
      window.open('/privacy.html');
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
