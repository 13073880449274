<!--eslint-disable-->
<template>
  <div class="timeline">
    <div class="timeline-line"></div>
    <div v-for="(item, index) in developments" :key="index" class="timeline-item" :class="{ 'right': index % 2 !== 0 }">
      <div class="timeline-item-content" :class="{ 'right-content': index % 2 === 0 }">
        <div class="timeline-item-content-date">
          <div class="timeline-item-date" :class="{'timeline-item-start': index % 2 !== 0}">
            <div class="timeline-item-date-c" :class="{ 'c_right': index % 2 !== 0 }"></div>
            <img :src="index % 2 === 0 ? yearPng : yearPng2" />
            <div class="timeline-item-date-t" :class="{ 'right_t': index % 2 !== 0 }">{{ item.year }}</div>
          </div>
          <div class="timeline-item-description trans_all_3">{{ item[`introduce${attributeLang}`] }}</div>
        </div>
        <div class="timeline-item-content-img">
          <img class="timeline-item-content-img-h trans_all_3" :src="`${viewPath}${item.filePath}`" />
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import yearPng from '@/assets/images/about/yearbg.png';
import yearPng2 from '@/assets/images/about/yearbg2.png';
import { findDevelopments } from "@/assets/api/apiService";
import { mapState } from "vuex";
import pubPath from "@/libs/path";
export default {
  name: 'Timeline',
  data () {
    return {
      viewPath: pubPath.imagePath,
      yearPng: yearPng,
      yearPng2: yearPng2,
      developments: [],
    }
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  mounted () {
    this.getDevelopments();
  },
  methods: {
    async getDevelopments () {
      let res = await findDevelopments();
      this.developments = res.data || [];
    },
  }
};
</script>

<style lang="scss" scoped>
.trans_all_3 {
  transition: all .3s ease-out 0s;
}
.timeline {
  position: relative;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: hsl(0deg 2.95% 82.32% / 28%);
  z-index: 1;
}

.timeline-item {
  display: flex;
  width: 50%;
  text-align: right;
  margin-left: -50%;
}

.timeline-item-content {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  .timeline-item-content-date {
    width: 40%;
    padding: 0 20px;
  }
}
.right-content {
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.timeline-item-start {
  justify-content: flex-start !important;
}
.timeline-item-date {
  position: relative;
  display: flex;
  justify-content: flex-end;
  .timeline-item-date-c {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1371EC;
    box-shadow: 0 0 0 4px rgba(19,113,236,0.2); /* 外圆环 */
    right: -26px;
    z-index: 1;
    top: 16px;
  }
  .c_right {
    left: -26px;
  }
  img {
    width: 100px;
  }
  .timeline-item-date-t {
    top: 12px;
    color: #FFFFFF;
    position: absolute;
    right: 30px;
    font-size: 18px;
  }
  .right_t {
    left: 30px !important;
    right: 0 !important;
  }
}

.timeline-item-description {
  color: #999999;
  padding: 0 10px;
  margin: 0;
}
.timeline-item-content-img {
  width: 40%;
  img {
    width: 100%;
  }
}

.right {
  margin-left: 50% !important;
  text-align: left !important;
}

@media (min-width: 1500px) {
  .timeline-item-description {
    font-size: 16px;
  }
}

@media  (min-width: 1200px) and (max-width: 1500px) {
  .timeline-item-description {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .timeline-item-description {
    font-size: 14px;
  }
}
</style>
