<!--eslint-disable-->
<template>
  <div class="m_company_structure">
    <el-collapse v-model="activeNames">
      <el-collapse-item :name="`${index + 1 + ''}`" v-for="(it, index) in data" :key="index">
        <div slot="title" class="m_company_structure_head">
          <div class="m_company_structure_head_title">
            <img src="../../../../assets/images/investor/soling-logo.png"/>
            <span>{{it[`name${attributeLang}`]}}</span>
          </div>
          <div class="m_company_structure_head_job">
            {{it[`title${attributeLang}`]}}
          </div>
        </div>
        <div class="m_company-content-item-text">{{it[`introduce${attributeLang}`]}}</div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { findBoardMembers } from "@/assets/api/apiService";
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "@/view/InvestorRelations/attrNames";

export default {
  data () {
    return {
      data: [],
      activeNames: '1'
    }
  },
  computed: {
    ...mapState({
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
    this.findBoardMembers();
  },
  methods: {
    async findBoardMembers () {
      let res = await findBoardMembers();
      this.data = res.data;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
