<!--eslint-disable-->
<template>
  <div class="m_contact_content">
    <div class="productm_content_container_title m_first_content m_first_header">
      {{attrDatas['about_contact_content_title']}}
    </div>
    <div class="contact-content-container">
      <div class="contact-content-main">
        <div class="contact-main-img">
          <img src="../../../../../assets/images/about/concat.png" />
        </div>
        <div class="contact-main-desc">
          <div class="contact-main-desc-item">
            <div v-if="attrDatas['about_contact_option1']" class="contact-main-desc-item_phone">
              <div class="contact-main-desc-item_phone_title">
                {{attrDatas['about_contact_option1'].split('\n')[0]}}
              </div>
              <div class="contact-main-desc-item_phone_desc">
                <div v-for="(it, index) in attrDatas['about_contact_option1'].split('\n')" v-if="index !== 0">{{it}}</div>
              </div>
            </div>
          </div>
          <div class="contact-main-desc-item">
            <div v-if="attrDatas['about_contact_option2']" class="contact-main-desc-item_phone">
              <div class="contact-main-desc-item_phone_title">
                {{attrDatas['about_contact_option2'].split('\n')[0]}}
              </div>
              <div class="contact-main-desc-item_phone_desc">
                <div v-for="(it, index) in attrDatas['about_contact_option2'].split('\n')" v-if="index !== 0">{{it}}</div>
              </div>
            </div>
          </div>
          <div class="contact-main-desc-item">
            <div v-if="attrDatas['about_contact_option3']" class="contact-main-desc-item_phone">
              <div class="contact-main-desc-item_phone_title">
                {{attrDatas['about_contact_option3'].split('\n')[0]}}
              </div>
              <div class="contact-main-desc-item_phone_desc">
                <div v-for="(it, index) in attrDatas['about_contact_option3'].split('\n')" v-if="index !== 0">{{it}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../../attrNames";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
