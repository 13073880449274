/*eslint-disable*/
export default {
  menu: {
    home: 'Home',
    test1: 'Test1',
    test2: 'Test2'
  },
  content: {
    home: 'Hello Home',
    test1: 'Hello Test1',
    test2: 'Hello Test2'
  }
}
