/* eslint-disable */
import pubPath from "@/libs/path";

/** 调用浏览器storage
 * @param key
 * @param value
 * @param boolean
 */
export function setStorage(key, value, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.sessionStorage
  } else {
    storageObj = window.localStorage
  }
  if (Object.prototype.toString.call(value) === '[object Object]' || Object.prototype.toString.call(value) === '[object Array]') {
    value = JSON.stringify(value)
  }
  if(key=='token'){
    console.log(value,typeof value)
  }
  storageObj.setItem(key, value)
}

export function getStorage(key, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.sessionStorage
  } else {
    storageObj = window.localStorage
  }
  return storageObj.getItem(key)
}

export function removeStorage(key, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.sessionStorage
  } else {
    storageObj = window.localStorage
  }
  storageObj.removeItem(key)
}

/**
 * 格式化日期
 * @param  {[type]} fmt [description]
 * @return {[type]}     [description]
 */
export function DateFormat(time, fmt) {
  if (!time) return null;
  const date = new Date(time)
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  }
  return fmt;
}

export function getAttrValue(datas, keys) {
  if (!datas) return;
  let attrDatas = {};
  const viewPath = pubPath.imagePath;
  keys.forEach(key => {
    const res = datas.find(d => d.elementKey === key);
    if (!res) return;
    let value = res.text || res.textArea;
    if (res.fileId) value = viewPath + res.filePath;
    attrDatas[key] = value;
  });
  return attrDatas;
}


// rem
export function rem() {
  let doc = document;
  let win = window;
  let docEl = doc.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize";
  win.recalc = function() {
    var clientWidth = docEl.clientWidth;
    if (!clientWidth) return;
    var fontSize = 20 * (clientWidth / 320) + "px";
    docEl.style.fontSize = fontSize;
  };
  win.recalc();
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, win.recalc, false);
  doc.addEventListener("DOMContentLoaded", win.recalc, false);
};
