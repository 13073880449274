<!--eslint-disable-->
<template>
  <div class="news-detail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{breadcrumbName.home[`menuName${attributeLang}`]}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'News', query: {type: 1} }">
        {{breadcrumbName.news[`menuName${attributeLang}`]}}
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'News', query: {type: this.$route.query.type} }">
        {{breadcrumbName.newsChild[`menuName${attributeLang}`]}}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{attrDatas['information_news_word01']}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="news-detail-title">
      {{detailData.newsTitle}}
    </div>
    <div class="news-detail-date">
      {{attrDatas['information_news_word02']}} {{DateFormat(detailData.publishTime, 'yyyy-MM-dd')}}
    </div>
    <div class="news-detail-content">
      <div class="" v-html="detailData.newsContent"></div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { DateFormat, getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "@/view/News/attrNames";
import { detailNews } from "@/assets/api/apiService";
import pubPath from "@/libs/path";

export default {
  props: {
    data: Object,
  },
  data () {
    return {
      viewPath: pubPath.imagePath,
      detailData: {},
    }
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    breadcrumbName () {
      if (!this.menuTree) return {home: {}, news: {}, newsChild: {}};
      const homeRes = this.menuTree.find(m => m.webUrl === 'Home') || {};
      const newsRes = this.menuTree.find(m => m.webUrl === 'News') || {child: []};
      if (!newsRes) return {home: {}, news: {}, newsChild: {}};
      const newsChildRes = newsRes.child.find(m => +m.childIndex === +this.$route.query.type) || [];
      return {
        home: homeRes,
        news: newsRes,
        newsChild: newsChildRes
      }
    }
  },
  watch: {

  },
  mounted () {
    this.detailNews();
    window.scrollTo(0, 0);
  },
  methods: {
    DateFormat,
    async detailNews () {
      const res = await detailNews({id: this.$route.query.id});
      this.detailData = res.data;
      this.detailData.newsContent = this.detailData.newsContent.replace(/<video/g, '<video style="max-width: 100%"');
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
