<!--eslint-disable-->
<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
export default {
  name: 'App',
  methods: {}
}
</script>
