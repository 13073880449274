<!--eslint-disable-->
<template>
  <div class="understand-content">
    <div class="understand-content-title mgt-20">
      <div class="understand-content-title1 trans_all_3" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_understand_title1']}}</div>
      <div class="understand-content-title2 trans_all_3" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_understand_desc1']}}</div>
    </div>
    <div class="understand-content-main">
      <div class="understand-main-head">
        <div class="understand-main-head-right">
          <img class="understand-head-right-bg" src="../../../../assets/images/about/solingbg.png"/>
          <div class="understand-head-right-desc">
            <div class="head-right-desc-logo">
              <img
                v-if="attrDatas[`about_understand_leftlogo`]"
                :src="attrDatas[`about_understand_leftlogo`]"
              />
              <img
                v-if="!attrDatas[`about_understand_leftlogo`]"
                src="../../../../assets/images/about/solinglogo2.png" />
              <div class="head-right-desc-text1">{{attrDatas['about_understand_leftlogo_dsc01']}}</div>
              <div class="head-right-desc-text2">{{attrDatas['about_understand_leftlogo_dsc02']}}</div>
            </div>
          </div>
        </div>
        <div class="understand-main-head-left">
          <div class="understand-head-left-title trans_all_3">
            {{attrDatas['about_understand_slogan']}}
          </div>
          <div class="understand-head-left-num">
            <div class="understand-left-module" v-for="i in 6">
              <div class="understand-left-module-n">
                <div class="understand-left-module-text trans_all_3">
                  <span class="font-init-family">{{attrDatas[`about_understand_option${i}`]}}</span>
                  <div class="understand-left-module-unit understand-content-title2 trans_all_3">
                    <p>{{attrDatas[`about_understand_unit${i}`]}}</p>
                  </div>
                  <div class="understand-left-module-line"></div>
                </div>
              </div>
              <div class="understand-left-module-desc understand-content-title2 trans_all_3">
                {{attrDatas[`about_understand_option${i}_desc`]}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="understand-main-container">
        <div class="understand-content-title">
          <div class="understand-content-title1 trans_all_3">{{attrDatas['about_understand_title2']}}</div>
          <div class="understand-content-title2 trans_all_3">{{attrDatas['about_understand_desc2']}}</div>
        </div>
        <div class="understand-container-step">
          <TimeLine/>
        </div>
        <div class="understand-container-culture">
          <div class="understand-content-title">
            <div class="understand-content-title1 trans_all_3 color1">{{attrDatas['home_culture_title']}}</div>
            <div class="understand-content-title2 trans_all_3 color1">{{attrDatas['home_culture_desc']}}</div>
          </div>
          <div class="understand-culture-main">
            <div class="understand-culture-main-item">
              <img src="../../../../assets/images/about/qiyewenhua1.png"/>
              <div class="understand-culture-main-item-title">{{attrDatas['home_culture_right1_title']}}</div>
              <div class="understand-culture-main-item-desc">{{attrDatas['home_culture_right1_desc']}}</div>
            </div>
            <div class="understand-culture-main-item">
              <img src="../../../../assets/images/about/qiyewenhua2.png"/>
              <div class="understand-culture-main-item-title">{{attrDatas['home_culture_right2_title']}}</div>
              <div class="understand-culture-main-item-desc">{{attrDatas['home_culture_right2_desc']}}</div>
            </div>
            <div class="understand-culture-main-item">
              <img src="../../../../assets/images/about/qiyewenhua3.png"/>
              <div class="understand-culture-main-item-title">{{attrDatas['home_culture_right3_title']}}</div>
              <div class="understand-culture-main-item-desc">{{attrDatas['home_culture_right3_desc']}}</div>
            </div>
            <div class="understand-culture-main-item">
              <img src="../../../../assets/images/about/qiyewenhua4.png"/>
              <div class="understand-culture-main-item-title">{{attrDatas['home_culture_right4_title']}}</div>
              <div class="understand-culture-main-item-desc">{{attrDatas['home_culture_right4_desc']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";
import TimeLine from '@/components/TimeLine';

export default {
  components: {
    TimeLine
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
