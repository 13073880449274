<!--eslint-disable-->
<template>
  <div class="investor-hotline">
    <div class="investor-structure-title">
      <div class="content-title1">{{attrDatas['investor_investor_title']}}</div>
      <div class="content-title2">{{attrDatas['investor_investor_desc']}}</div>
    </div>

    <div class="investor-structure-content">
      <el-descriptions :column="2">
        <el-descriptions-item :label="attrDatas['investor_investor_name1']">{{attrDatas['investor_investor_value1']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name2']">{{attrDatas['investor_investor_value2']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name3']">{{attrDatas['investor_investor_value3']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name4']">{{attrDatas['investor_investor_value4']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name5']">{{attrDatas['investor_investor_value5']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name6']">{{attrDatas['investor_investor_value6']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name7']">{{attrDatas['investor_investor_value7']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name8']">{{attrDatas['investor_investor_value8']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name9']">{{attrDatas['investor_investor_value9']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name10']">{{attrDatas['investor_investor_value10']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name11']">{{attrDatas['investor_investor_value11']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name12']">{{attrDatas['investor_investor_value12']}}</el-descriptions-item>
        <el-descriptions-item :label="attrDatas['investor_investor_name13']">{{attrDatas['investor_investor_value13']}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "@/view/InvestorRelations/attrNames";

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      contentConfig: state => state.publicData.contentConfig,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
