<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="about-main">
      <!--顶部banner-->
      <div class="about-main-banner">
        <div class="about-main-banner-info animate__animated animate__fadeIn animate__delay-0.8s">
          <div class="mc_a1_topbox-info_text1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_banner_title']}}</div>
          <div class="mc_a1_topbox-info_text2">{{attrDatas['about_banner_desc']}}</div>
        </div>
        <div class="about-main-banner-img">
          <img v-if="attrDatas['about_banner_image']" :src="attrDatas['about_banner_image']"/>
          <img v-if="!attrDatas['about_banner_image']" src="../../assets/images/about/banner.png"/>
        </div>
        <div class="banner-tab banner-tab-media">
          <div class="banner-tab-main">
            <div
              v-for="tab in tabData"
              :keys="tab.id"
              @click="onChangeTab(tab.childIndex)"
              :class="{'tab-active':tabActive === tab.childIndex}"
              class="banner-tab-content">
              {{tab[`menuName${attributeLang}`]}}
            </div>
          </div>
        </div>
      </div>
      <Understand class="animate__animated animate__fadeIn" v-show="tabActive === 1"/>
      <Glory class="animate__animated animate__fadeIn" v-show="tabActive === 2"/>
      <Custom class="animate__animated animate__fadeIn" v-show="tabActive === 3"/>
      <Contact class="animate__animated animate__fadeIn" v-show="tabActive === 4"/>
      <Recruit class="animate__animated animate__fadeIn" v-show="tabActive === 5"/>
    </div>
    <MAbout v-if="isMobile"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "./attrNames";
import Understand from './components/Understand';
import Glory from './components/Glory';
import Custom from './components/Custom';
import Contact from './components/Contact';
import Recruit from './components/Recruit';
import MAbout from "./m_about";
import { saveBrowseHistory } from "@/assets/api/apiService";

export default {
  components: {
    Understand,
    Glory,
    Custom,
    Contact,
    Recruit,
    MAbout
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
    }
  },
  mounted () {
    this.getSaveBrowseHistory();
  },
  methods: {
    getSaveBrowseHistory (language) {
      const local = {zh: 1, en: 2, ja: 3};
      const lang_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);
      saveBrowseHistory({languageType: local[language] || local[lang_str], moduleType: 2});
    },
    onChangeTab (id) {
      this.tabActive = id;
      this.$router.push({name: 'About', query: {type: this.tabActive}});
    }
  },
  watch: {
    language (val) {
      if (val) this.getSaveBrowseHistory(val);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
