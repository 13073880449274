<!--eslint-disable-->
<template>
  <div class="m_timeline">
    <div class="timeline-line"></div>
    <div v-for="(item, index) in developments" :key="index" class="timeline-item">
      <div class="timeline-item-content" :class="{ 'right-content': index % 2 === 0 }">
        <div class="timeline-item-content-date">
          <div class="timeline-item-date">
            <div class="timeline-item-date-c"></div>
            <div style="display: grid;display: -ms-grid;">
              <img :src="yearPng2" />
            </div>
            <div class="timeline-item-date-t">{{ item.year }}</div>
          </div>
          <div class="timeline-item-description trans_all_3">{{ item[`introduce${attributeLang}`] }}</div>
        </div>
        <div class="timeline-item-content-img">
          <img class="timeline-item-content-img-h trans_all_3" :src="`${viewPath}${item.filePath}`" />
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import yearPng from '@/assets/images/about/yearbg.png';
import yearPng2 from '@/assets/images/about/yearbg2.png';
import { findDevelopments } from "@/assets/api/apiService";
import { mapState } from "vuex";
import pubPath from "@/libs/path";
export default {
  name: 'Timeline',
  data () {
    return {
      viewPath: pubPath.imagePath,
      yearPng: yearPng,
      yearPng2: yearPng2,
      developments: [],
    }
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  mounted () {
    this.getDevelopments();
  },
  methods: {
    async getDevelopments () {
      let res = await findDevelopments();
      this.developments = res.data || [];
    },
  }
};
</script>

<style lang="scss" scoped>
.m_timeline {
  background: #FFFFFF;
  position: relative;
  width: 100%;
  padding: 0.5rem 0.7rem;
  display: flex;
  flex-direction: column;

  .timeline-line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: hsl(0deg 2.95% 82.32% / 28%);
    z-index: 1;
  }

  .timeline-item {
    display: flex;
    width: 100%;
    margin-bottom: 0.8rem;
  }

  .timeline-item-content {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    .timeline-item-content-date {
      width: 65%;
      padding: 0 0.2rem;
    }
  }
  .timeline-item-date {
    position: relative;
    display: flex;
    justify-content: flex-start;
    .timeline-item-date-c {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background: #1371EC;
      box-shadow: 0 0 0 4px rgba(19,113,236,0.2); /* 外圆环 */
      left: -0.35rem;
      z-index: 1;
      top: 0.65rem;
    }
    img {
      width: 4rem;
    }
    .timeline-item-date-t {
      top: 0.4rem;
      color: #FFFFFF;
      position: absolute;
      left: 1.2rem;
      font-size: 0.8rem;
    }
  }

  .timeline-item-description {
    color: #999999;
    padding-left: 0.2rem;
    //padding: 0 10px;
    font-size: 0.6rem;
    margin: 0;
  }
  .timeline-item-content-img {
    display: grid;
    display: -ms-grid;
    width: 35%;
    img {
      width: 100%;
    }
  }
}
</style>
