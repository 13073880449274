<!--eslint-disable-->
<template>
  <div class="m_about_main">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['about_banner_image_mobile']" src="../../../assets/images/about/m_banner.png"/>
      <img v-if="attrDatas['about_banner_image_mobile']" :src="attrDatas['about_banner_image_mobile']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
          v-if="currentMenu"
        >
          {{currentMenu[`menuName${attributeLang}`]}}
        </div>
        <div class="m_head_container_title_2">
          {{attrDatas['about_banner_title']}}
        </div>
      </div>
    </div>
    <div class="m_about_main">
      <Understand v-if="+$route.query.type === 1"/>
      <Glory v-if="+$route.query.type === 2"/>
      <Custom v-if="+$route.query.type === 3"/>
      <Contact v-if="+$route.query.type === 4"/>
      <Recruit v-if="+$route.query.type === 5"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "../attrNames";
import Understand from './components/Understand';
import Glory from './components/Glory';
import Custom from './components/Custom';
import Contact from './components/Contact';
import Recruit from './components/Recruit';

export default {
  components: {
    Understand,
    Glory,
    Custom,
    Contact,
    Recruit,
  },
  metaInfo: {
    title: '关于索菱 Soling | SOLING 索菱股份有限公司官网 车联网硬件及软件服务提供商，互联网智能车机提供者',
    meta: [
      { name: 'description', content: '索菱（SOLING）股份有限公司，车联网硬件及软件服务提供商，互联网智能车机提供者' },
      { name: 'keywords', content: '索菱 车联网 导航 车载 互联网智能车机' },
      // 其他 meta 标签
    ],
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    currentMenu () {
      const res = this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
      const temp = res.child || [];
      return temp.find(it => +it.childIndex === +this.$route.query.type)
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
    }
  },
  methods: {
    onChangeTab (id) {
      this.tabActive = id;
      this.$router.push({name: 'About', query: {type: this.tabActive}});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
