<!--eslint-disable-->
<template>
  <div class="qamgt-content">
    <div class="scrollIntoView_div" ref="qaMgt"></div>
    <div class="qamgt-content-title mgt-20">
      <div class="qamgt-content-title1">{{attrDatas['ability_qc_title']}}</div>
      <div class="qamgt-content-title2">{{attrDatas['ability_qc_desc']}}</div>
    </div>
    <div class="qamgt-content-main">
      <div class="qa-mgt-content">
        <div class="qa-mgt-content-main">
          <div class="qa-mgt-content-item qa-item-bj1">
            <div class="qa-item-num qa-item-num_f">01</div>
            <div class="qa-item-title qa-item-title_f">{{attrDatas['ability_qc_option1']}}</div>
            <div class="qa-item-desc qa-item-desc_f" v-if="attrDatas['ability_qc_content1']">
              <div class="qa-item-desc-text" v-for="str in attrDatas['ability_qc_content1'].split('\n')">
                {{str}}
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="qa-mgt-content-item qa-item-bj2">
            <div class="qa-item-num qa-item-num_f">02</div>
            <div class="qa-item-title qa-item-title_f">{{attrDatas['ability_qc_option2']}}</div>
            <div class="qa-item-desc qa-item-desc_f" v-if="attrDatas['ability_qc_content2']">
              <div class="qa-item-desc-text" v-for="str in attrDatas['ability_qc_content2'].split('\n')">
                {{str}}
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="qa-mgt-content-item qa-item-bj3">
            <div class="qa-item-num qa-item-num_f">03</div>
            <div class="qa-item-title qa-item-title_f">{{attrDatas['ability_qc_option3']}}</div>
            <div class="qa-item-desc qa-item-desc_f" v-if="attrDatas['ability_qc_content3']">
              <div class="qa-item-desc-text" v-for="str in attrDatas['ability_qc_content3'].split('\n')">
                {{str}}
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="qa-mgt-content-item qa-item-bj4">
            <div class="qa-item-num qa-item-num_f">04</div>
            <div class="qa-item-title qa-item-title_f">{{attrDatas['ability_qc_option4']}}</div>
            <div class="qa-item-desc qa-item-desc_f" v-if="attrDatas['ability_qc_content4']">
              <div class="qa-item-desc-text" v-for="str in attrDatas['ability_qc_content4'].split('\n')">
                {{str}}
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="qa-mgt-content-item qa-item-bj5">
            <div class="qa-item-num qa-item-num_f">05</div>
            <div class="qa-item-title qa-item-title_f">{{attrDatas['ability_qc_option5']}}</div>
            <div class="qa-item-desc qa-item-desc_f" v-if="attrDatas['ability_qc_content5']">
              <div class="qa-item-desc-text" v-for="str in attrDatas['ability_qc_content5'].split('\n')">
                {{str}}
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="qa-mgt-content-item qa-item-bj6">
            <div class="qa-item-num qa-item-num_f">06</div>
            <div class="qa-item-title qa-item-title_f">{{attrDatas['ability_qc_option6']}}</div>
            <div class="qa-item-desc qa-item-desc_f" v-if="attrDatas['ability_qc_content6']">
              <div class="qa-item-desc-text" v-for="str in attrDatas['ability_qc_content6'].split('\n')">
                {{str}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
    scrollIntoView () {
      return this.$refs.qaMgt.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
