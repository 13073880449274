/*eslint-disable*/
export const attrs = [
  // banner区
  'product_banner_image', // banner
  'product_banner_title', // 标题
  'product_banner_desc', // 描述
  // 产品概况
  'product_01_world_title',
  'product_01_world_desc',
  'product_01_contact_desc01',
  'product_01_contact_desc02',
  'product_01_contact_desc03',
  'product_01_contact_desc04',
  'product_01_contact_desc05',
  'product_01_contact_bgpic',
  'product_01_contact_title',
  'product_01_contact_desc',
  'product_02_world_title',
  'product_02_world_desc',
  'product_03_world_title',
  'product_03_world_desc',
  'product_04_world_title',
  'product_04_world_desc',
  'product_05_world_title',
  'product_05_world_desc',
  'product_02_close',
  'product_02_open',
  'product_01_contact_bgpic',
  // h5
  'product_banner_image_mobile',
  'product_01_contact_bgpic_h5'
];
