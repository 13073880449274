/* eslint-disable */
import {setStorage, getStorage} from "@/libs/tool";

// 获取当前浏览器设置的语言
let language_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);

const userAgent = navigator.userAgent.toLowerCase();
let isMobile = /iphone|ipad|ipod|android|webos|blackberry|windows phone|iemobile|wpdesktop/i.test(userAgent);
if (!isMobile) isMobile = window.innerWidth < 1024;


const publicData = {
  state: {
    menuTree: [], // 菜单树
    language: language_str, // 当前语言
    contentConfig: null, // 所有配置数据
    isMobile: isMobile, // 是否移动端
  },

  mutations: {
    SET_MENU_TREE: (state, menuTree) => {
      state.menuTree = menuTree
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      setStorage('soling_language', language, false);
    },
    SET_CONTENT_CONFIG: (state, contentConfig) => {
      state.contentConfig = contentConfig;
      setStorage('soling_content_config', contentConfig, false);
    },
    SET_IS_MOBILE: (state, isMobile) => {
      state.isMobile = isMobile;
    }
  },
  actions: {
    // 用户信息
    getMenuTreeData: ({ commit }, data) => {
      commit('SET_MENU_TREE', data);
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setContentConfig ({ commit }, contentConfig) {
      commit('SET_CONTENT_CONFIG', contentConfig);
    },
    setIsMobile ({ commit }, isMobile) {
      commit('SET_IS_MOBILE', isMobile);
    }
  }
};

export default publicData;
