/*eslint-disable*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store'
import routes from "@/router/route";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history', // 后端支持可开
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.metaInfo) {
        store.dispatch('SetMetaInfo', to.meta.metaInfo);
    }
    next()
})

export default router;
