<!--eslint-disable-->
<template>
  <div class="ability-content">
    <!--制造能力-->
    <div class="ability-content-title mgt-20">
      <div class="ability-content-title1">{{attrDatas['produce_ability_title1']}}</div>
      <div class="ability-content-title2">{{attrDatas['produce_ability_desc1']}}</div>
    </div>
    <div class="intelligent-content">
      <div class="grid-container1">
        <div class="left-boxes">
          <div class="box1">
            <div class="box_child box_child_bj1">
              <div class="box_content_desc box_content_desc_f">
                <div>{{attrDatas['produce_ability_opition1']}}</div>
              </div>
              <div class="box_content_title box_content_title_f">
                {{attrDatas['produce_ability_content1']}}
              </div>
            </div>
            <div class="spacer"></div>
            <div class="box_child box_child_bj2">
              <div class="box_content_desc box_content_desc_f">
                <div>{{attrDatas['produce_ability_opition2']}}</div>
              </div>
              <div class="box_content_title box_content_title_f">
                {{attrDatas['produce_ability_content2']}}
              </div>
            </div>
            <div class="spacer"></div>
            <div class="box_child box_child_bj3">
              <div class="box_content_desc box_content_desc_f">
                <div>{{attrDatas['produce_ability_opition3']}}</div>
              </div>
              <div class="box_content_title box_content_title_f">
                {{attrDatas['produce_ability_content3']}}
              </div>
            </div>
          </div>
          <div class="box2">
            <div class="box_child box_child_bj4">
              <div class="box_content_desc box_content_desc_f">
                <div>{{attrDatas['produce_ability_opition4']}}</div>
              </div>
              <div class="box_content_title box_content_title_f">
                {{attrDatas['produce_ability_content4']}}
              </div>
            </div>
            <div class="spacer"></div>
            <div class="box_child box_child_bj5">
              <div class="box_content_desc box_content_desc_f">
                <div>{{attrDatas['produce_ability_opition5']}}</div>
              </div>
              <div class="box_content_title box_content_title_f">
                {{attrDatas['produce_ability_content5']}}
              </div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="box3">
            <img class="right-box-img" src="../../../../assets/images/manufacturing/grid-right.png"/>
          </div>
          <div class="right-box-content">
            <div class="box_content_desc box_content_desc_f">
              <div>{{attrDatas['produce_ability_opition6']}}</div>
            </div>
            <div class="box_content_title box_content_title_f">
              {{attrDatas['produce_ability_content6']}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--制造能力-->
    <div class="ability-content-title mgt-20">
      <div class="ability-content-title1">{{attrDatas['produce_ability_title2']}}</div>
      <div class="ability-content-title2">{{attrDatas['produce_ability_desc2']}}</div>
    </div>
    <div class="laboratory-content">
      <div class="laboratory-content-main">
        <div class="laboratory-content-item">
          <img class="laboratory-content-icon" src="../../../../assets/images/manufacturing/cem1.png"/>
          <div class="laboratory-content-title laboratory-content-title_f">{{attrDatas['produce_ability_lab_name1']}}</div>
          <div class="laboratory-content-desc laboratory-content-desc_f">
            <div class="laboratory-content-desc-text"
                 v-if="attrDatas['produce_ability_lab_desc1']"
                 v-html="attrDatas['produce_ability_lab_desc1'].replace(/\n/g, '<br/>')">
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="laboratory-content-item">
          <img class="laboratory-content-icon" src="../../../../assets/images/manufacturing/cem2.png"/>
          <div class="laboratory-content-title laboratory-content-title_f">{{attrDatas['produce_ability_lab_name2']}}</div>
          <div class="laboratory-content-desc laboratory-content-desc_f">
            <div class="laboratory-content-desc-text"
                 v-if="attrDatas['produce_ability_lab_desc2']"
                 v-html="attrDatas['produce_ability_lab_desc2'].replace(/\n/g, '<br/>')">
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="laboratory-content-item">
          <img class="laboratory-content-icon" src="../../../../assets/images/manufacturing/cem3.png"/>
          <div class="laboratory-content-title laboratory-content-title_f">{{attrDatas['produce_ability_lab_name3']}}</div>
          <div class="laboratory-content-desc laboratory-content-desc_f">
            <div class="laboratory-content-desc-text"
                 v-if="attrDatas['produce_ability_lab_desc3']"
                 v-html="attrDatas['produce_ability_lab_desc3'].replace(/\n/g, '<br/>')">
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="laboratory-content-item">
          <img class="laboratory-content-icon" src="../../../../assets/images/manufacturing/cem4.png"/>
          <div class="laboratory-content-title laboratory-content-title_f">{{attrDatas['produce_ability_lab_name4']}}</div>
          <div class="laboratory-content-desc laboratory-content-desc_f">
            <div class="laboratory-content-desc-text"
                 v-if="attrDatas['produce_ability_lab_desc4']"
                 v-html="attrDatas['produce_ability_lab_desc4'].replace(/\n/g, '<br/>')">
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="laboratory-content-item">
          <img class="laboratory-content-icon" src="../../../../assets/images/manufacturing/cem5.png"/>
          <div class="laboratory-content-title laboratory-content-title_f">{{attrDatas['produce_ability_lab_name5']}}</div>
          <div class="laboratory-content-desc laboratory-content-desc_f">
            <div class="laboratory-content-desc-text"
                 v-if="attrDatas['produce_ability_lab_desc5']"
                 v-html="attrDatas['produce_ability_lab_desc5'].replace(/\n/g, '<br/>')">
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="laboratory-content-item">
          <img class="laboratory-content-icon" src="../../../../assets/images/manufacturing/cem6.png"/>
          <div class="laboratory-content-title laboratory-content-title_f">{{attrDatas['produce_ability_lab_name6']}}</div>
          <div class="laboratory-content-desc laboratory-content-desc_f">
            <div class="laboratory-content-desc-text"
                 v-if="attrDatas['produce_ability_lab_desc6']"
                 v-html="attrDatas['produce_ability_lab_desc6'].replace(/\n/g, '<br/>')">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
