/*eslint-disable*/
import axios from 'axios';

// 创建axios实例
const service = axios.create({
    timeout: 0 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
    config => {
      if (config.method === 'get') {
        if (config.params) {
          config.params['t'] = new Date().getTime();
        } else {
          config.params = {};
          config.params['t'] = new Date().getTime();
        }
      }
        return config;
    },
    error => {
        Promise.reject(error);
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            return Promise.reject(response.data)
        } else {
            return response.data
        }
    },
    error => {
        return Promise.reject(error);
    }
)

export default service;
