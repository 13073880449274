/*eslint-disable*/
export const attrs = [
  // banner区
  'information_banner_image', // banner
  'information_banner_title', // 标题
  'information_banner_desc', // 描述
  'information_mobile_banner_image', // 描述
  'information_news_word01', // 正文
  'information_news_word02', // 发布于
  // 公司新闻
  'information_news_title', // 模块标题
  'information_news_desc', // 模块描述
  // 行业资讯
  'information_industry_title', // 模块标题
  'information_industry_desc', // 模块描述
];
