<!--eslint-disable-->
<template>
  <div class="m_news_detail">
    <div class="news-detail-title">
      {{detailData.newsTitle}}
    </div>
    <div class="news-detail-date">
      {{DateFormat(detailData.publishTime, 'yyyy-MM-dd')}}
    </div>
    <div class="news-detail-content">
      <div class="" v-html="detailData.newsContent"></div>
    </div>
    <div class="news-step">
      <div class="news-step-item" v-if="detailData.previousId" @click="jumpNews(detailData.previousId)">上一篇</div>
      <div class="news-step-item" v-if="detailData.latterId" @click="jumpNews(detailData.latterId)">下一篇</div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { DateFormat, getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "@/view/News/attrNames";
import { detailNews } from "@/assets/api/apiService";
import pubPath from "@/libs/path";

export default {
  props: {
    data: Object,
  },
  data () {
    return {
      viewPath: pubPath.imagePath,
      detailData: {},
    }
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    }
  },
  watch: {

  },
  mounted () {
    this.detailNews();
  },
  methods: {
    DateFormat,
    async detailNews () {
      const res = await detailNews({id: this.$route.params.id});
      this.detailData = res.data;
      this.detailData.newsContent = this.detailData.newsContent.replace(/<img/g, '<img style="max-width: 100%;display: block;"');
      this.detailData.newsContent = this.detailData.newsContent.replace(/<video/g, '<video style="max-width: 100%;display: block;"');
    },
    jumpNews (id) {
      window.location.href = '/news/' + id;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
