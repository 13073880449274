<!--eslint-disable-->
<template>
  <div class="m_capacity">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['ability_banner_image_mobile']" src="../../../assets/images/manufacturing/m_banner.png"/>
      <img v-if="attrDatas['ability_banner_image_mobile']" :src="attrDatas['ability_banner_image_mobile']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
        >
          {{currentMenu[`menuName${attributeLang}`]}}
        </div>
        <div class="m_head_container_title_2">
          {{attrDatas['ability_banner_title']}}
        </div>
      </div>
    </div>

    <div class="m_capacity_container">
      <div class="productm_content_container_title m_first_content m_first_header">{{attrDatas['ability_innovate_title']}}</div>
      <!--创新能力-->
      <div class="m_capacity_innovate">
        <div class="m_capacity_innovate_item" v-for="n in 3">
          <div class="m_capacity_innovate_head">
            <div class="m_capacity_innovate_head_title">
              {{attrDatas[`ability_innovate_0${n}tit_h5`]}}
            </div>
            <div class="m_capacity_innovate_head_desc">
              {{attrDatas[`ability_innovate_0${n}desc_h5`]}}
            </div>
            <img src="../../../assets/images/capacity/m_innovate_bg.png"/>
          </div>
          <div class="m_capacity_innovate_tag" v-if="attrDatas[`ability_innovate_0${n}tab_h5`]">
            <div
              class="m_capacity_innovate_tag_item"
              v-for="t in attrDatas[`ability_innovate_0${n}tab_h5`].split('\n')">
              {{t}}
            </div>
          </div>
        </div>
      </div>
      <!--全球支持-->
      <div class="productm_content_container_title ma-g-2">
        {{attrDatas['ability_world_title']}}
        <div class="productm_content_container_desc">{{attrDatas['home_global_image_text']}}</div>
      </div>
      <div class="m_global_container">
        <img src="../../../assets/images/capacity/m_global.gif"/>
      </div>
      <!--质量体系-->
      <div class="productm_content_container_title ma-g-2">
        {{attrDatas['ability_qms_title']}}
      </div>
      <MCarouselGloy :swiperList="certificatesList"/>
      <!--研发管理-->
      <div class="productm_content_container_title ma-g-2">
        {{attrDatas['ability_rd_title']}}
        <div class="productm_content_container_desc">{{attrDatas['ability_rd_desc_h5']}}</div>
      </div>
      <div class="m_RD_content_main">
        <img v-if="attrDatas['ability_rd_image_mobile']" :src="attrDatas['ability_rd_image_mobile']"/>
        <img v-if="!attrDatas['ability_rd_image_mobile']" src="../../../assets/images/capacity/m_RD_bg.png"/>
      </div>
      <!--质量管理-->
      <div class="productm_content_container_title ma-g-2">
        {{attrDatas['ability_qc_title']}}
      </div>
      <div class="m_qamgt_container">
        <div
          class="m_qamgt_container_item"
          :class="`m_qt_bg${i}`"
          v-for="i in 6"
        >
          <div class="m_qamgt_container_item_title">
            <span>0{{i}}</span>
            {{attrDatas[`ability_qc_option${ i }`]}}
          </div>
          <div v-if="attrDatas[`ability_qc_content${ i }`]" class="m_qamgt_container_item_desc">
            <p class="qa-item-desc-text"
                 v-for="str in attrDatas[`ability_qc_content${ i }`].split('\n')">
              {{str}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "../attrNames";
import { findCertificates } from "@/assets/api/apiService";
// import MCarouselGloy from "@/components/M_CarouselGloy";
import MCarouselGloy from "@/components/M_CarouselGloy4";

export default {
  components: {
    MCarouselGloy
  },
  metaInfo: {
    title: '索菱能力 Soling | SOLING 索菱股份有限公司官网 车联网硬件及软件服务提供商，互联网智能车机提供者',
    meta: [
      { name: 'description', content: '索菱（SOLING）股份有限公司，车联网硬件及软件服务提供商，互联网智能车机提供者' },
      { name: 'keywords', content: '索菱 车联网 导航 车载 互联网智能车机' },
      // 其他 meta 标签
    ],
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    currentMenu () {
      return this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
      certificatesList: []
    }
  },
  mounted () {
    this.getCertificates();
    this.$nextTick(() => {
      this.viewRef(this.$route.query.type);
    })
  },
  methods: {
    onChangeTab (id) {
      this.tabActive = id;
      this.viewRef(id);
    },
    async getCertificates () {
      let res = await findCertificates({type: 2});
      const temp = res.data || [];
      this.certificatesList = temp;
    },
    viewRef (type) {

    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
