/*eslint-disable*/
export const attrs = [
  // banner区
  'produce_banner_image', // banner
  'produce_banner_image_mobile', // banner
  'produce_banner_title', // 标题
  'produce_banner_desc', // 描述
  // 智造能力
  'produce_ability_title1', // 模块01标题
  'produce_ability_desc1', // 模块01描述
  'produce_ability_title2', // 模块01标题
  'produce_ability_desc2', // 模块01描述
  'produce_ability_title3', // 模块01标题
  'produce_ability_desc3', // 模块01描述
  // 内容-1
  'produce_ability_opition1',
  'produce_ability_content1',
  'produce_ability_opition2',
  'produce_ability_content2',
  'produce_ability_opition3',
  'produce_ability_content3',
  'produce_ability_opition4',
  'produce_ability_content4',
  'produce_ability_opition5',
  'produce_ability_content5',
  'produce_ability_opition6',
  'produce_ability_content6',
  // 内容-2
  'produce_ability_lab_name1',
  'produce_ability_lab_desc1',
  'produce_ability_lab_name2',
  'produce_ability_lab_desc2',
  'produce_ability_lab_name3',
  'produce_ability_lab_desc3',
  'produce_ability_lab_name4',
  'produce_ability_lab_desc4',
  'produce_ability_lab_name5',
  'produce_ability_lab_desc5',
  'produce_ability_lab_name6',
  'produce_ability_lab_desc6',
  // 实验认证
  'produce_experiment_title',
  'produce_experiment_desc',
  'produce_experiment_option1',
  'produce_experiment_content1',
  'produce_experiment_option2',
  'produce_experiment_content2',
  'produce_experiment_option3',
  'produce_experiment_content3',
  'produce_experiment_option4',
  'produce_experiment_content4',
  'produce_experiment_image',
  'produce_experiment_image_mobile',
];
