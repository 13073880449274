<!--eslint-disable-->
<template>
  <div v-show="isShow.global" class="global-content animate__animated animate__fadeInUp animate__delay-0.8s" ref="globalRef">
    <div class="scrollIntoView_div" ref="global"></div>
    <div class="global-content-title">
      <div class="global-content-title1">{{attrDatas['ability_world_title']}}</div>
      <div class="global-content-title2">{{attrDatas['ability_world_desc']}}</div>
      <div class="global-content-title3 global-content-title3_f">{{attrDatas['ability_world_slogan']}}</div>
    </div>
    <div class="global-content-steps">
      <el-tabs tab-position="right" v-model="activeName">
        <el-tab-pane name="1">
          <div class="global-steps-item" slot="label">
            <div class="global-steps-text" v-if="attrDatas['ability_world_word1']">
              <p v-for="it in attrDatas['ability_world_word1'].split('\n')">
                {{it}}
              </p>
            </div>
            <div v-show="activeName === '1'"
                 class="global-steps-img global-steps-img_top animate__animated animate__fadeIn animate__delay-0.8s">
              <img v-if="!attrDatas['ability_world_image1']" src="../../../../assets/images/capacity/tab_img1.png"/>
              <img v-if="attrDatas['ability_world_image1']" :src="attrDatas['ability_world_image1']"/>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="2">
          <div class="global-steps-item" slot="label">
            <div class="global-steps-text">
              <div class="global-steps-text" v-if="attrDatas['ability_world_word2']">
                <p v-for="it in attrDatas['ability_world_word2'].split('\n')">
                  {{it}}
                </p>
              </div>
            </div>
            <div v-show="activeName === '2'"
                 class="global-steps-img global-steps-img_top animate__animated animate__fadeIn animate__delay-0.8s">
              <img v-if="!attrDatas['ability_world_image2']" src="../../../../assets/images/capacity/tab_img2.png"/>
              <img v-if="attrDatas['ability_world_image2']" :src="attrDatas['ability_world_image2']"/>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="3">
          <div class="global-steps-item" slot="label">
            <div class="global-steps-text" v-if="attrDatas['ability_world_word3']">
              <p v-for="it in attrDatas['ability_world_word3'].split('\n')">
                {{it}}
              </p>
            </div>
            <div v-show="activeName === '3'"
                 class="global-steps-img global-steps-img_top animate__animated animate__fadeIn animate__delay-0.8s">
              <img v-if="!attrDatas['ability_world_image3']" src="../../../../assets/images/capacity/tab_img3.png"/>
              <img v-if="attrDatas['ability_world_image3']" :src="attrDatas['ability_world_image3']"/>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="4">
          <div class="global-steps-item" slot="label">
            <div class="global-steps-text" v-if="attrDatas['ability_world_word4']">
              <p v-for="it in attrDatas['ability_world_word4'].split('\n')">
                {{it}}
              </p>
            </div>
            <div v-show="activeName === '4'"
                 class="global-steps-img global-steps-img_top animate__animated animate__fadeIn animate__delay-0.8s">
              <img v-if="!attrDatas['ability_world_image4']" src="../../../../assets/images/capacity/tab_img4.png"/>
              <img v-if="attrDatas['ability_world_image4']" :src="attrDatas['ability_world_image4']"/>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="global-content-main">
      <video autoplay muted loop id="mc_a1t_video12" v-if="attrDatas['ability_world_bg']">
        <source :src="attrDatas['ability_world_bg']" type="video/mp4">
      </video>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      activeName: '1',
      timer: null,
      isShow: {
        global: false
      }
    }
  },
  mounted () {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
    this.currentInterVal();
  },
  methods: {
    currentInterVal () {
      this.timer = setInterval(() => {
        const num = +this.activeName + 1;
        if (num === 5) {
          this.activeName = '1';
        } else {
          this.activeName = num + '';
        }
      }, 3000)
    },
    scrollIntoView () {
      return this.$refs.global.scrollIntoView({ behavior: 'smooth' });
    },
    handleScroll () {
      if (!this.$refs.globalRef) {
        return;
      }
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      const globalRef = this.$refs.globalRef.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, globalRef)) this.isShow.global = true;
    },
    // 判断滚动条是否在某个元素上
    isScrolledToElement (viewportHeight, divRect) {
      return divRect.top + 200 <= viewportHeight && divRect.bottom >= 0;
    },
  },
  beforeDestroy () {
    clearTimeout(this.timer);
    document.body.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
