<!--eslint-disable-->
<template>
  <div class="financial-records">
    <div class="financial-structure-title">
      <div class="content-title1">{{attrDatas['investor_finance_title']}}</div>
      <div class="content-title2">{{attrDatas['investor_finance_desc']}}</div>
    </div>
    <div class="financial-structure-content">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
            type="index"
            :label="attrDatas['investor_finance_form1']"
            align="center"
            width="80">
        </el-table-column>
        <el-table-column
            prop="name"
            align="center"
            :label="attrDatas['investor_finance_form2']">
          <template slot-scope="scope">
            <span>{{scope.row[`name${attributeLang}`]}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="address"
            width="80"
            align="center"
            :label="attrDatas['investor_finance_form3']">
          <template slot-scope="scope">
            <div style="height: 20px;">
              <img class="financial-structure-content-icon"
                   @click="onDown(scope.row)"
                   src="../../../../assets/images/investor/download.svg"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { pageReportAnnual } from "@/assets/api/apiService";
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "@/view/InvestorRelations/attrNames";

export default {
  data () {
    return {
      tableData: []
    }
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
    this.getReportData();
  },
  methods: {
    async getReportData () {
      let res = await pageReportAnnual({pageSize: 100, pageNum: 1});
      this.tableData = res.data.content;
    },
    onDown (row) {
      window.location.href = '/api/web/download?fileId=' + row.fileId + '&fileName=' + row[`name${this.attributeLang}`];
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
