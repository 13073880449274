<!--eslint-disable-->
<template>
  <div class="company-structure">
    <div class="company-structure-title">
      <div class="content-title1">{{attrDatas['investor_board_title']}}</div>
      <div class="content-title2">{{attrDatas['investor_board_desc']}}</div>
    </div>
    <div class="company-structure-content">
      <div class="company-structure-content-item" v-for="it in data">
        <div class="company-content-item-title">
          <div class="company-content-item-title-name">
            <img src="../../../../assets/images/investor/soling-logo.png"/>
            <span>
              {{it[`name${attributeLang}`]}}
              <!-- {{it.nameCn}} -->
            </span>
          </div>
          <div class="company-content-item-title-job">
              {{it[`title${attributeLang}`]}}
            <!-- {{it.titleCn}} -->
          </div>
        </div>
        <div class="company-content-item-text">
          {{it[`introduce${attributeLang}`]}}
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { findBoardMembers } from "@/assets/api/apiService";
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "@/view/InvestorRelations/attrNames";

export default {
  data () {
    return {
      data: []
    }
  },
  computed: {
    ...mapState({
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
    this.findBoardMembers();
  },
  methods: {
    async findBoardMembers () {
      let res = await findBoardMembers();
      this.data = res.data;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
