<!--eslint-disable-->
<template>
  <div class="m_investor_hotline">
    <div class="m_investor_hotline_form">

      <div class="m_investor_hotline_form_item" v-for="i in 13">
        <div class="m_investor_hotline_form_label">{{attrDatas[`investor_investor_name${i}`]}}</div>
        <div class="m_investor_hotline_form_text">{{attrDatas[`investor_investor_value${i}`]}}</div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "@/view/InvestorRelations/attrNames";

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      contentConfig: state => state.publicData.contentConfig,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
