<!--eslint-disable-->
<template>
  <div v-loading="loading" class="m_product_detail">
    <div class="m_product_header">
      <div class="m_product_header_title">
        <div
          v-for="(it, index) in devices"
          @click="onChangeDevice(it)"
          :class="{'active_product': activeId === it.id}"
          class="m_product_header_title_item">
          {{it[`categoryName${attributeLang}`]}}
        </div>
      </div>
    </div>
    <div v-if="products && products.length" class="m_product_detail_main">
      <div class="m_product_detail_item"
           v-for="d in products">
        <div class="m_product_detail_title">
          {{d[`productName${attributeLang}`]}}
        </div>
        <div class="m_product_detail_img">
          <img :src="`${viewPath}${d.imageFilePath}`" />
        </div>
        <div class="m_product_detail_content" v-if="d[`productIntroduce${attributeLang}`]">
          <div class="m_product_detail_desc">
            <div class="m_product_detail_desc_text" v-for="(t, index) in d[`productIntroduce${attributeLang}`].split('\n')">
              <div class="pro-circular"></div>
              <span>{{t}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MNoData v-if="!(products && products.length) && loading"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../../attrNames";
import pubPath from "@/libs/path";
import MNoData from "@/components/MNoData";

export default {
  components: {
    MNoData
  },
  props: {
    proType: Array|Object
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    devices () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      const child = res.child || [];
      let data = [];
      const i = +this.$route.query.type - 1;
      if (child && child[i] && this.proType) {
        data = this.proType.filter(p => p.moduleId === child[i].id) || [];
        if (data[0]) {
          this.activeId = data[0].id;
          const temp = data[0].products || [];
          this.products = temp.map(p => {
            return {
              more: false,
              ...p
            }
          });
          this.loading = false;
        }
      }
      return data;
    },
    currentChild () {
      const menu = this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
      let child = {};
      if (menu.child) {
        child = menu.child.find(m => +m.childIndex === +this.$route.query.type) || {}
      }
      return child;
    },
  },
  mounted () {

  },
  data () {
    return {
      loading: true,
      activeId: null,
      products: [],
      viewPath: pubPath.imagePath,
    }
  },
  methods: {
    onChangeDevice (row) {
      this.moreId = null;
      this.products = [];
      this.activeId = row.id;
      this.products = row.products || [];
      this.products = this.products.map(p => {
        return {
          more: false,
          ...p
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
