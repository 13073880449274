/*eslint-disable*/
export const attrs = [
  // banner区
  'home_banner_video', // banner视频
  'home_banner_title', // 标题
  'home_banner_desc', // 描述
  'home_banner_small_slogan', // 小宣传语
  'home_banner_video_h5', // 小宣传语
  // 我们能为您提供的服务
  'home_service_title', // 模块标题
  'home_service_desc', // 模块描述
  // 智造中心
  'home_develop_title',
	'home_develop_desc',
	'home_develop_logo',
	'home_develop_maindesc',
	'home_develop_image',
  // 生产智造
  'home_production_title', // 模块标题
  'home_production_desc', // 模块描述
  'home_production_left1_title', // 左边2-1标题
  'home_production_left1_desc', // 左边2-1描述
  'home_production_left2_title', // 左边2-2标题
  'home_production_left2_desc', // 左边2-2描述
  'home_production_right1_title', // 右边3-1标题
  'home_production_right1_desc', // 右边3-1描述-Web
  'home_production_right2_title', // 右边3-2标题
  'home_production_right2_desc', // 右边3-2描述-Web
  'home_production_right3_title', // 右边3-3标题
  'home_production_right3_desc', // 右边3-3描述-Web
  'home_production_left1_title_h5', // 右边3-3描述-Web
  'home_production_left1_desc_h5', // 右边3-3描述-Web
  // 全球支持
  'home_global_title', // 模块标题
  'home_global_desc', // 模块描述
  'home_global_image', // 内容图-Web
  'home_global_image_text', // 模块描述
  'home_global_image_h5', // 内容图-H5
  // 最新动态
  'home_dynamic_title', // 模块标题
  'home_dynamic_desc', // 模块描述
  'home_dynamic_button', // 更多按钮
  // 企业文化
  'home_culture_title', // 模块标题
  'home_culture_desc', // 模块描述
  'home_culture_image', // 模块描述
  'home_culture_right1_title', // 文化选项1（公用）
  'home_culture_right1_desc', // 文化内容1（公用）
  'home_culture_right2_title', // 文化选项2（公用）
  'home_culture_right2_desc', // 文化内容2（公用）
  'home_culture_right3_title', // 文化选项3（公用）
  'home_culture_right3_desc', // 文化内容3（公用）
  'home_culture_right4_title', // 文化选项4（公用）
  'home_culture_right4_desc', // 文化内容4（公用）
  // 客户及合作伙伴
  'home_partner_title', // 模块标题
  'home_partner_desc', // 模块描述
  'home_partner_image', // 合作伙伴大图-Web
  'home_partner_image_h5', // 合作伙伴大图-H5
];
