<!--eslint-disable-->
<template>
  <div class="swiper-container-main3" style="position: relative;">
    <div class="swiper-container" id="swiper2">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="glory-item3">
            <img :src="`${viewPath}${item.filePath}`"  @click="imgPreview(`${viewPath}${item.filePath}`)"/>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next-init swiper-button-init swiper-button-next2">
      <img class="swiper_img swiper_img_left" src="../assets/images/carouse_left.png"/>
    </div>
    <div class="swiper-button-prev-init swiper-button-init swiper-button-prev2">
      <img class="swiper_img swiper_img_left" src="../assets/images/carousel_right.png"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import Swiper from "swiper";
import 'swiper/css/swiper.min.css';
import { mapState } from "vuex";
import pubPath from "@/libs/path";

export default {
  props: {
    swiperList: Array|Object
  },
  data() {
    return {
      viewPath: pubPath.imagePath,
    };
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      var mySwiper1 = new Swiper("#swiper2", {
        slidesPerView: 3,
        // loop: true,
        // autoplay: {
        //   delay: 4000, // 自动播放间隔为3000毫秒（3秒）
        //   disableOnInteraction: false
        // },
        navigation: {
          nextEl: ".swiper-button-prev2",
          prevEl: ".swiper-button-next2",
          disabledClass: 'my-button-disabled',
        }
      });
    },
    imgPreview(src) {
      window.open(src, "_blank");
    }
  },
};
</script>
<style scoped lang="scss">
.swiper-container-main3 {
  padding: 0 15%;
  .swiper-button-init {
    top: 40%;
    transform: translateY(-50%);
    position: absolute;
    img {
      cursor: pointer;
      width: 30px;
    }
  }
  .swiper-button-next-init {
    left: 10%;
  }
  .swiper-button-prev-init {
    right: 10%;
  }
  .swiper-container {
    height: 100%;
  }
  .glory-item3 {
    padding: 2%;
    width: 100%;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      height: 100%;
    }
  }
}

</style>
