<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="manufacturing-main">
      <!--顶部banner-->
      <div class="manufacturing-main-banner">
        <div class="manufacturing-main-banner-info animate__animated animate__fadeIn animate__delay-0.8s">
          <div class="mc_a1_topbox-info_text1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['produce_banner_title']}}</div>
          <div class="mc_a1_topbox-info_text2">{{attrDatas['produce_banner_desc']}}</div>
        </div>
        <div class="manufacturing-main-banner-img">
          <img v-if="attrDatas['produce_banner_image']" :src="attrDatas['produce_banner_image']"/>
          <img v-if="!attrDatas['produce_banner_image']" src="../../assets/images/manufacturing/banner.png"/>
        </div>
        <div class="banner-tab banner-tab-media">
          <div class="banner-tab-main">
            <div
              v-for="tab in tabData"
              :keys="tab.id"
              @click="onChangeTab(tab.childIndex)"
              :class="{'tab-active':tabActive === tab.childIndex}"
              class="banner-tab-content">
              {{tab[`menuName${attributeLang}`]}}
            </div>
          </div>
        </div>
      </div>
      <Intelligent ref="intelligentRef" class="animate__animated animate__fadeIn animate__delay-0.5s"></Intelligent>
      <Identification ref="identificationRef" class="animate__animated animate__fadeIn animate__delay-1s"></Identification>
    </div>
    <MManufacturing v-if="isMobile"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "./attrNames";
import Intelligent from './components/Intelligent';
import Identification from './components/Identification';
import MManufacturing from './m_manufacturing/index';
import { saveBrowseHistory } from "@/assets/api/apiService";

export default {
  components: {
    Intelligent,
    Identification,
    MManufacturing
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
    }
  },
  watch: {
    language (val) {
      if (val) this.getSaveBrowseHistory(val);
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (+this.$route.query.type === 2 && !this.isMobile) {
        setTimeout(() => {
          this.$refs.identificationRef.scrollIntoView();
        }, 500)
      }
    })
    this.getSaveBrowseHistory();
  },
  methods: {
    getSaveBrowseHistory (language) {
      const local = {zh: 1, en: 2, ja: 3};
      const lang_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);
      saveBrowseHistory({languageType: local[language] || local[lang_str], moduleType: 5});
    },
    onChangeTab (id) {
      this.tabActive = id;
      if (+id === 1) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        this.$refs.identificationRef.scrollIntoView();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
