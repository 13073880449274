<!--eslint-disable-->
<template>
  <div class="m_understand_content">
    <div class="productm_content_container_title m_first_content m_first_header">
      {{attrDatas['about_banner_title']}}
      <div class="productm_content_container_desc">{{attrDatas['about_understand_desc1_h5']}}</div>
    </div>
    <div class="m_understand_main">
      <div class="m_understand_head">
        <div class="m_understand_head_title">
          {{attrDatas['about_understand_slogan']}}
        </div>
        <div class="m_understand_head_container">
          <div class="m_understand_head_container_item">
            <div class="understand-left-module" :class="{'understand-left-module_width2': [3, 6].includes(i)}" v-for="i in 6">
              <div class="understand-left-module-n">
                <div class="understand-left-module-text trans_all_3">
                  <span class="font-init-family">{{attrDatas[`about_understand_option${i}`]}}</span>
                  <div class="understand-left-module-unit trans_all_3">
                    <p>{{attrDatas[`about_understand_unit${i}`]}}</p>
                  </div>
                  <div class="understand-left-module-line"></div>
                </div>
              </div>
              <div class="understand-left-module-desc trans_all_3">
                {{attrDatas[`about_understand_option${i}_desc`]}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--发展历程-->
    <div class="productm_content_container_title mg-t">
      {{attrDatas['about_understand_title2']}}
    </div>
    <TimeLine/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "../../../attrNames";
import TimeLine from '@/components/M_TimeLine';

export default {
  components: {
    TimeLine
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
