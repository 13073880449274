<!--eslint-disable-->
<template>
  <div ref="rdMain" class="RD-content animate__animated animate__fadeIn animate__delay-1s">
    <div class="scrollIntoView_div" ref="rdMag"></div>
    <div class="RD-content-title">
      <div class="RD-content-title1">{{attrDatas['ability_rd_title']}}</div>
      <div class="RD-content-title2">{{attrDatas['ability_rd_desc']}}</div>
    </div>
    <div class="RD-content-main">
      <img v-if="attrDatas['ability_rd_image']" :src="attrDatas['ability_rd_image']"/>
      <img v-if="!attrDatas['ability_rd_image']" src="../../../../assets/images/capacity/RD_bg.png"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  mounted () {
  },
  methods: {
    scrollIntoView () {
      return this.$refs.rdMag.scrollIntoView({ behavior: 'smooth' });
    }
  },
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
