/*eslint-disable*/
import Home from '@/view/Home/Home.vue';
import About from '@/view/About/index.vue';
import Product from '@/view/Product/index.vue';
import Capacity from '@/view/Capacity/index.vue';
import Manufacturing from '@/view/Manufacturing/index.vue';
import News from '@/view/News/index.vue';
import NewsDetail from '@/view/News/m_news/Detail/index.vue';
import InvestorRelations from '@/view/InvestorRelations/index.vue';
import Layout from '@/layout/index.vue';

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: { name: 'Home' },
        metaInfo: {
            title: 'Home',
            keywords: '关键词1',
            description: '描述1',
            titleLocal: 'menu.home'
        },
        children: [
            {
                path: 'home',
                name: 'Home',
                meta: {
                    metaInfo: {
                        title: '索菱首页',
                        keywords: '索菱首页',
                        description: '索菱首页',
                        titleLocal: 'menu.home'
                    },
                },
                component: Home
            },
            {
                path: 'about',
                name: 'About',
                meta: {
                    metaInfo: {
                        title: '关于索菱',
                        keywords: '关于索菱',
                        description: '关于索菱',
                        titleLocal: 'menu.home'
                    },
                },
                component: About
            },
            {
                path: 'product',
                name: 'Product',
                meta: {
                    metaInfo: {
                        title: '产品与服务',
                        keywords: '产品与服务',
                        description: '产品与服务',
                        titleLocal: 'menu.home'
                    },
                },
                component: Product
            },
            {
                path: 'capacity',
                name: 'Capacity',
                meta: {
                    metaInfo: {
                        title: '索菱能力',
                        keywords: '索菱能力',
                        description: '索菱能力',
                        titleLocal: 'menu.home'
                    },
                },
                component: Capacity
            },
            {
                path: 'manufacturing',
                name: 'Manufacturing',
                meta: {
                    metaInfo: {
                        title: '生产制造',
                        keywords: '生产制造',
                        description: '生产制造',
                        titleLocal: 'menu.home'
                    },
                },
                component: Manufacturing
            },
            {
                path: 'news',
                name: 'News',
                meta: {
                    metaInfo: {
                        title: '咨询中心',
                        keywords: '咨询中心',
                        description: '咨询中心',
                        titleLocal: 'menu.home'
                    },
                },
                component: News
            },
            {
                path: 'news/:id',
                name: 'NewsDetail',
                meta: {
                    metaInfo: {
                        title: '咨询中心',
                        keywords: '咨询中心',
                        description: '咨询中心',
                        titleLocal: 'menu.home'
                    },
                },
                component: NewsDetail
            },
            {
                path: 'investorRelations',
                name: 'InvestorRelations',
                meta: {
                    metaInfo: {
                        title: '投资者关系',
                        keywords: '投资者关系',
                        description: '投资者关系',
                        titleLocal: 'menu.home'
                    },
                },
                component: InvestorRelations
            },
        ]
    },
];
export default routes;
