<!--eslint-disable-->
<template>
  <div class="quality-content animate__animated animate__fadeIn animate__delay-1s">
    <div class="scrollIntoView_div" ref="quality"></div>
    <div class="quality-content-title mgt-20">
      <div class="quality-content-title1">{{attrDatas['ability_qms_title']}}</div>
      <div class="quality-content-title2">{{attrDatas['ability_qms_desc']}}</div>
    </div>
    <div class="quality-content-main">
      <div class="quality-main-certificate1">
        <div class="quality-certificate1-row">
          <div
            v-for="item in certificatesList"
            class="quality-certificate1-row-item">
            <img :src="`${viewPath}${item.filePath}`" @click="imgPreview(`${viewPath}${item.filePath}`)"/>
          </div>
        </div>
      </div>

      <div class="quality-main-certificate2">
        <CarouselGloy3 v-if="swiperList && swiperList.length" :swiperList="swiperList"/>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";
import { findCertificates } from "@/assets/api/apiService";
import CarouselGloy3 from '@/components/CarouselGloy3';
import 'swiper/css/swiper.min.css';
import pubPath from "@/libs/path";


export default {
  components: {
    CarouselGloy3,
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      viewPath: pubPath.imagePath,
      certificatesList: [],
      swiperList: [],
      isShow: false
    }
  },
  async mounted () {
    await this.getCertificates();
  },
  methods: {
    async getCertificates () {
      let res = await findCertificates({type: 2});
      const temp = res.data || [];
      this.certificatesList = temp.slice(0, 4);
      this.swiperList = temp.slice(4, temp.length);
    },
    imgPreview(src) {
      window.open(src, "_blank");
    },
    scrollIntoView () {
      return this.$refs.quality.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
