/*eslint-disable*/
import '@babel/polyfill';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import i18n from "./i18n";
import "@/styles/index.scss"; // global css
import "element-ui/lib/theme-chalk/index.css";
import "animate.css";
import ElementUI from "element-ui";

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(ElementUI);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
