<!--eslint-disable-->
<template>
  <div class="glory-content">
    <div class="glory-content-title mgt-20">
      <div class="glory-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_honor_title']}}</div>
      <div class="glory-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_honor_desc']}}</div>
    </div>
    <div class="glory-content-main">
      <div class="glory-content-carouse">
        <CarouselGloy />
      </div>
    </div>
    <div class="glory-content-main" style="min-height: unset;margin-bottom: 50px;">
      <div class="glory-content-carouse">
        <CarouselGloy2 />
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";
import CarouselGloy from '@/components/CarouselGloy';
import CarouselGloy2 from '@/components/CarouselGloy2';

export default {
  components: {
    CarouselGloy,
    CarouselGloy2,
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
