<!--eslint-disable-->
<template>
  <div class="identification-content">
    <div class="scrollIntoView_div" ref="identificationContent"></div>
    <div class="identification-content-title mgt-20">
      <div class="identification-content-title1">{{attrDatas['produce_experiment_title']}}</div>
      <div class="identification-content-title2">{{attrDatas['produce_experiment_desc']}}</div>
    </div>
    <div class="identification-content-main">
      <div class="identification-main-grid">
        <div class="iden_left-boxes">
          <div class="iden_left-boxes-content">
            <div class="iden_left-boxes-item iden_left-boxes-item_mg">
              <div class="iden_left-boxes-title iden_left-boxes-title_f">{{attrDatas['produce_experiment_option1']}}</div>
              <div class="iden_left-boxes-desc iden_left-boxes-desc_f">{{attrDatas['produce_experiment_content1']}}</div>
            </div>
            <div class="iden_left-boxes-item iden_left-boxes-item_mg">
              <div class="iden_left-boxes-title iden_left-boxes-title_f">{{attrDatas['produce_experiment_option2']}}</div>
              <div class="iden_left-boxes-desc iden_left-boxes-desc_f">{{attrDatas['produce_experiment_content2']}}</div>
            </div>
            <div class="iden_left-boxes-item iden_left-boxes-item_mg">
              <div class="iden_left-boxes-title iden_left-boxes-title_f">{{attrDatas['produce_experiment_option3']}}</div>
              <div class="iden_left-boxes-desc iden_left-boxes-desc_f">{{attrDatas['produce_experiment_content3']}}</div>
            </div>
            <div class="iden_left-boxes-item iden_left-boxes-item_mg">
              <div class="iden_left-boxes-title iden_left-boxes-title_f">{{attrDatas['produce_experiment_option4']}}</div>
              <div class="iden_left-boxes-desc iden_left-boxes-desc_f">{{attrDatas['produce_experiment_content4']}}</div>
            </div>
          </div>
        </div>
        <div class="iden_right-boxes">
          <img v-if="attrDatas['produce_experiment_image']" :src="attrDatas['produce_experiment_image']"/>
          <img v-if="!attrDatas['produce_experiment_image']" src="../../../../assets/images/manufacturing/cnas.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
    scrollIntoView () {
      return this.$refs.identificationContent.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
