<!--eslint-disable-->
<template>
  <div class="swiper-container-main1" style="position: relative;">
    <div class="swiper-container" id="swiper1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="glory-card">
            <img src="../assets/images/about/glory_card.png" />
            <div class="glory-card-year">{{item.year}}</div>
            <div class="glory-card-content" v-if="item[`content${attributeLang}`]">
              <div
                v-for="data in item[`content${attributeLang}`].split('\n')"
                class="glory-swiper-desc-item">
                <div class="glory-circular"></div>
                <div class="glory-swiper-desc-text">
                  {{data}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next1">
      <img class="swiper_img swiper_img_left" src="../assets/images/swiper_left.png"/>
    </div>
    <div class="swiper-button-prev1">
      <img class="swiper_img swiper_img_right" src="../assets/images/swiper_right.png"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import Swiper from "swiper";
import 'swiper/css/swiper.min.css';
import { mapState } from "vuex";
import pubPath from "@/libs/path";
import { findHonors } from "@/assets/api/apiService";

export default {
  props: {
    swiperList: Array|Object
  },
  data() {
    return {
      viewPath: pubPath.imagePath,
    };
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  async mounted() {
    await this.getHonors();
    this.getData();
  },
  methods: {
    getData() {
      var mySwiper1 = new Swiper("#swiper1", {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-prev1",
          prevEl: ".swiper-button-next1",
          disabledClass: 'init-button-disabled',
        },
      });
    },
    async getHonors () {
      let res = await findHonors();
      this.swiperList = res.data || [];
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container-main1 {
  margin-top: 0.7rem;
  .swiper-container {
    height: 100%;
  }
  .swiper_img {
    width: 1.5rem;
    position: absolute;
    top: 40%;
    z-index: 99;
  }
  .swiper_img_left {
    left: 1%;
  }
  .swiper_img_right {
    right: 1%;
  }
}
.glory-card {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 92%;
  }
  .glory-card-year {
    color: #FFFFFF;
    position: absolute;
    top: 0.52rem;
    font-size: 0.7rem;
    left: 1.85rem;
  }
  .glory-card-content {
    position: absolute;
    top: 20%;
    width: 80%;
    .glory-swiper-desc-item {
      margin-bottom: 0.2rem;
      position: relative;
      padding-left: 0.7rem;
      .glory-circular {
        left: 0;
        top: 0.25rem;
        position: absolute;
        width: 0.3rem ;
        height: 0.3rem;
        border-radius: 50%;
        margin-right: 0.4rem;
        background: #5A607F;
      }
      .glory-swiper-desc-text {
        font-size: 0.6rem;
        color: #5A607F;
        word-break: break-all;
      }
    }
  }
}
.init-button-disabled {
  opacity: 0;
}
</style>
