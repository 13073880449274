/*eslint-disable*/
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './langs/en.js';
import zhLocale from './langs/zh.js';
import jpLocale from './langs/jp.js';

Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale
    },
    zh: {
        ...zhLocale
    },
    jp: {
        ...jpLocale
    }
};

const i18n = new VueI18n({
    locale: 'zh', // set locale
    messages // set locale messages
});

export default i18n;
