<!--eslint-disable-->
<template>
  <div class="m_recruit_content">
    <el-collapse v-model="activeNames">
      <el-collapse-item :name="`${index + 1 + ''}`" v-for="(it, index) in recruitments" :key="index">
        <div slot="title" class="m_company_head">
          <div class="m_company_head_title">
            <img src="../../../../../assets/images/investor/soling-logo.png"/>
            <span>{{it[`positionName${attributeLang}`]}}</span>
          </div>
          <div class="m_company_head_job">
            {{it[`address${attributeLang}`]}}
          </div>
        </div>
        <div class="recruit-container-desc">
          <div class="recruit-container-icon">
            <img src="../../../../../assets/images/about/ico-join1.png"/>
            <div class="recruit-container-icon-desc">{{attrDatas['about_join_duty_title']}}</div>
          </div>
          <div class="recruit-container-text">
            <div v-if="it[`duty${attributeLang}`]"
                 v-for="item in it[`duty${attributeLang}`].split('\n')"
                 class="recruit-container-text-item">
              {{item}}
            </div>
          </div>
        </div>
        <div class="recruit-container-ask">
          <div class="recruit-container-icon">
            <img src="../../../../../assets/images/about/ico-join2.png"/>
            <div class="recruit-container-icon-desc">{{attrDatas['about_join_post_title']}}</div>
          </div>
          <div class="recruit-container-text">
            <div v-if="it[`post${attributeLang}`]"
                 v-for="item in it[`post${attributeLang}`].split('\n')"
                 class="recruit-container-text-item">
              {{item}}
            </div>
          </div>
        </div>
        <div class="recruit-container-email">{{it[`email${attributeLang}`]}}</div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../../attrNames";
import { pageRecruitment } from "@/assets/api/apiService";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      activeNames: ['1'],
      recruitments: []
    }
  },
  mounted () {
    this.getPageRecruitment();
  },
  methods: {
    async getPageRecruitment () {
      const res = await pageRecruitment({pageNum: 1, pageSize: 100});
      this.recruitments = res.data.content;
      console.log(this.recruitments);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
