<!--eslint-disable-->
<template>
  <div class="m_product_main">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['product_banner_image_mobile']" src="../../../assets/images/product/m_banner.png"/>
      <img v-if="attrDatas['product_banner_image_mobile']" :src="attrDatas['product_banner_image_mobile']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
        >
          {{currentMenu[`menuName${attributeLang}`]}}
        </div>
        <div class="m_head_container_title_2">
          {{attrDatas['product_banner_title']}}
        </div>
      </div>
    </div>
    <div class="m_product_container">
      <Overview class="animate__animated animate__fadeIn" :proType="proType" v-show="tabActive === 1"/>
      <ProductDetail class="animate__animated animate__fadeIn" :proType="proType" v-show="tabActive !== 1"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "../attrNames";
import { productsList } from "@/assets/api/apiService";
import Overview from "./components/Overview";
import ProductDetail from "./components/ProductDetail";

export default {
  components: {
    Overview,
    ProductDetail,
  },
  metaInfo: {
    title: '产品与服务 Soling | SOLING 索菱股份有限公司官网 车联网硬件及软件服务提供商，互联网智能车机提供者',
    meta: [
      { name: 'description', content: '索菱（SOLING）股份有限公司，车联网硬件及软件服务提供商，互联网智能车机提供者' },
      { name: 'keywords', content: '索菱 车联网 导航 车载 互联网智能车机' },
      // 其他 meta 标签
    ],
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    currentMenu () {
      return this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
      proType: []
    }
  },
  mounted() {
    this.getProductsList();
  },
  methods: {
    onChangeTab (id) {
      this.tabActive = +id;
      this.$router.push({name: 'Product', query: {type: this.tabActive}});
    },
    async getProductsList () {
      let res = await productsList();
      this.proType = res.data;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
