<!--eslint-disable-->
<template>
  <div class="swiper-container-main" style="position: relative;">
    <div class="swiper-container" id="swiper1">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="glory-item">
            <img src="../assets/images/about/glory_card.png" />
            <div class="glory-item-content">
              <div class="glory-swiper-title">{{item.year}}</div>
              <div class="glory-swiper-desc" v-if="item[`content${attributeLang}`]">
                <div
                  v-for="data in item[`content${attributeLang}`].split('\n')"
                  class="glory-swiper-desc-item">
                  <div class="glory-circular"></div>
                  <div class="glory-swiper-desc-text">
                    {{data}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next-init swiper-button-init swiper-button-next1">
      <img class="swiper_img swiper_img_left" src="../assets/images/carouse_left.png"/>
    </div>
    <div class="swiper-button-prev-init swiper-button-init swiper-button-prev1">
      <img class="swiper_img swiper_img_left" src="../assets/images/carousel_right.png"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import Swiper from "swiper";
import 'swiper/css/swiper.min.css';
import { findHonors } from "@/assets/api/apiService";
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "@/view/Product/attrNames";
// import 'swiper/swiper-bundle.css';

export default {
  data() {
    return {
      swiperList: [],
    };
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  async mounted() {
    await this.getHonors();
    this.getData();
  },
  methods: {
    getData() {
      var mySwiper = new Swiper("#swiper1", {
        slidesPerView: 3,
        spaceBetween: 30,
        // loop: true,
        navigation: {
          nextEl: ".swiper-button-prev1",
          prevEl: ".swiper-button-next1",
          disabledClass: 'my-button-disabled',
        },
      });
    },
    async getHonors () {
      let res = await findHonors();
      this.swiperList = res.data || [];
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container-main {
  .swiper-button-init {
    top: 45%;
    transform: translateY(-50%);
    position: absolute;
    img {
      cursor: pointer;
      width: 25px;
    }
  }
  .swiper-button-next-init {
    left: -10px;
  }
  .swiper-button-prev-init {
    right: -10px;
  }
}
.swiper-container {
  height: 100%;
  margin: 0 30px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.glory-item {
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
  .glory-item-content {
    .glory-swiper-desc {
      position: absolute;
      top: 17%;
      left: 5%;
      width: 90%;
      .glory-swiper-desc-item {
        margin: 12px 0;
        display: flex;
        .glory-circular {
          margin-top: 5px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          margin-right: 5px;
          background: #5A607F;
        }
        .glory-swiper-desc-text {
          flex: 1;
          text-align: left;
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .glory-swiper-title {
    font-size: 18px;
    position: absolute;
    left: 8%;
    top: 3%;
    color: #FFFFFF;
  }
}

@media  (min-width: 1200px) and (max-width: 1500px) {
  .glory-swiper-title {
    font-size: 16px;
    position: absolute;
    left: 8%;
    top: 3%;
    color: #FFFFFF;
  }
}

@media (max-width: 1200px) {
  .glory-swiper-title {
    font-size: 14px;
    position: absolute;
    left: 8%;
    top: 3%;
    color: #FFFFFF;
  }
}
</style>
