<!--eslint-disable-->
<template>
  <div class="overview-content">
    <div class="overview-content-title">
      <div class="overview-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['product_01_world_title']}}</div>
      <div class="overview-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['product_01_world_desc']}}</div>
    </div>
    <div class="overview-module">
      <div class="overview-content-main">
        <div class="overview-product">
          <div class="overview-product-item overview-product-item_height overview-product-item_bg1">
            <div class="overview-pro-content">
              <div class="overview-pro-content-left">
                <div class="overview-pro-left-title" v-if="tabData && tabData[1] && types">
                  {{tabData[1][`menuName${attributeLang}`]}}
                </div>
                <div class="overview-pro-left-text" v-if="tabData && tabData[1]">
                  <ul>
                    <li v-for="d in types[tabData[1].id]">
                      <div class="pro-circular"></div>
                      <div>{{d[`categoryName${attributeLang}`]}}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="overview-pro-content-right">
                <img src="../../../../assets/images/product/more3.png" @click="onJump(tabData[1].childIndex)"/>
              </div>
            </div>
          </div>
          <div class="product-spacer"></div>

          <div class="overview-product-item overview-product-item_height overview-product-item_bg2">
            <div class="overview-pro-content">
              <div class="overview-pro-content-left">
                <div class="overview-pro-left-title" v-if="tabData && tabData[2] && types">
                  {{tabData[2][`menuName${attributeLang}`]}}
                </div>
                <div class="overview-pro-left-text" v-if="tabData && tabData[2]">
                  <ul>
                    <li v-for="d in types[tabData[2].id]">
                      <div class="pro-circular"></div>
                      <div>{{d[`categoryName${attributeLang}`]}}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="overview-pro-content-right">
                <img src="../../../../assets/images/product/more3.png" @click="onJump(tabData[2].childIndex)"/>
              </div>
            </div>
          </div>
          <div class="product-spacer"></div>

          <div class="overview-product-item overview-product-item_height overview-product-item_bg3">
            <div class="overview-pro-content">
              <div class="overview-pro-content-left">
                <div class="overview-pro-left-title" v-if="tabData && tabData[3] && types">
                  {{tabData[3][`menuName${attributeLang}`]}}
                </div>
                <div class="overview-pro-left-text" v-if="tabData && tabData[3]">
                  <ul>
                    <li v-for="d in types[tabData[3].id]">
                      <div class="pro-circular"></div>
                      <div>{{d[`categoryName${attributeLang}`]}}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="overview-pro-content-right">
                <img src="../../../../assets/images/product/more3.png" @click="onJump(tabData[3].childIndex)"/>
              </div>
            </div>
          </div>
          <div class="product-spacer"></div>

          <div class="overview-product-item overview-product-item_height overview-product-item_bg4">
            <div class="overview-pro-content">
              <div class="overview-pro-content-left">
                <div class="overview-pro-left-title" v-if="tabData && tabData[4] && types">
                  {{tabData[4][`menuName${attributeLang}`]}}
                </div>
                <div class="overview-pro-left-text" v-if="tabData && tabData[4]">
                  <ul>
                    <li v-for="d in types[tabData[4].id]">
                      <div class="pro-circular"></div>
                      <div>{{d[`categoryName${attributeLang}`]}}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="overview-pro-content-right">
                <img src="../../../../assets/images/product/more3.png" @click="onJump(tabData[4].childIndex)"/>
              </div>
            </div>
          </div>

          <div class="product-spacer"></div>
          <div class="overview-product-item overview-product-item_height overview-product-item_bg5">
            <div class="overview-pro-content">
              <div class="overview-pro-content-left">
                <div class="overview-pro-left-title" v-if="tabData && tabData[5] && types">
                  {{tabData[5][`menuName${attributeLang}`]}}
                </div>
                <div class="overview-pro-left-text" v-if="tabData && tabData[5]">
                  <ul>
                    <li v-for="d in types[tabData[5].id]">
                      <div class="pro-circular"></div>
                      <div>{{d[`categoryName${attributeLang}`]}}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="overview-pro-content-right">
                <img src="../../../../assets/images/product/more3.png" @click="onJump(tabData[5].childIndex)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="strategy-content strategy-content_mg">
        <div class="strategy-content-bg strategy-content-bg_height">
          <div class="strategy-bg-steps-line"></div>
          <div class="strategy-bg-steps">
            <div class="strategy-bg-steps-item">
              <img class="strategy-steps-icon" src="../../../../assets/images/product/solinglogo3.png"/>
              <div class="strategy-steps-text strategy-steps-text_f">{{attrDatas['product_01_contact_desc01']}}</div>
            </div>
            <div class="strategy-bg-steps-item">
              <img class="strategy-steps-icon" src="../../../../assets/images/product/solinglogo3.png"/>
              <div class="strategy-steps-text strategy-steps-text_f">{{attrDatas['product_01_contact_desc02']}}</div>
            </div>
            <div class="strategy-bg-steps-item">
              <img class="strategy-steps-icon" src="../../../../assets/images/product/solinglogo3.png"/>
              <div class="strategy-steps-text strategy-steps-text_f">{{attrDatas['product_01_contact_desc03']}}</div>
            </div>
            <div class="strategy-bg-steps-item">
              <img class="strategy-steps-icon" src="../../../../assets/images/product/solinglogo3.png"/>
              <div class="strategy-steps-text strategy-steps-text_f">{{attrDatas['product_01_contact_desc04']}}</div>
            </div>
            <div class="strategy-bg-steps-item">
              <img class="strategy-steps-icon" src="../../../../assets/images/product/solinglogo3.png"/>
              <div class="strategy-steps-text strategy-steps-text_f">{{attrDatas['product_01_contact_desc05']}}</div>
            </div>
          </div>
        </div>
        <div class="strategy-content-title strategy-content-title_mg">
          <div class="overview-content-title1 color1">{{attrDatas['product_01_contact_title']}}</div>
          <div class="overview-content-title2 color1 ling-h-22">{{attrDatas['product_01_contact_desc']}}</div>
        </div>
        <img v-if="attrDatas['product_01_contact_bgpic']" width="1278" :src="attrDatas['product_01_contact_bgpic']"/>
        <!-- <img v-if="!attrDatas['product_01_contact_bgpic']" width="1278" src="../../../../assets/images/product/strategy.png"/> -->
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  props: {
    proType: Array|Object
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    types () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      const child = res.child || [];
      let data = {};
      if (child && child[1]) {
        data[child[1].id] = this.proType.filter(p => p.moduleId === child[1].id) || [];
        data[child[2].id] = this.proType.filter(p => p.moduleId === child[2].id) || [];
        data[child[3].id] = this.proType.filter(p => p.moduleId === child[3].id) || [];
        data[child[4].id] = this.proType.filter(p => p.moduleId === child[4].id) || [];
        data[child[5].id] = this.proType.filter(p => p.moduleId === child[5].id) || [];
      }
      return data;
    },
  },
  methods: {
    onJump (index) {
      this.$router.push({name: 'Product', query: {type: index}});
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
