/*eslint-disable*/
export const attrs = [
  // banner区
  'ability_banner_image', // banner
  'ability_banner_title', // 标题
  'ability_banner_desc', // 描述
  // 创新
  'ability_innovate_title', // 标题
  'ability_innovate_desc', // 描述
  'ability_innovate_image',
  // 全球支持
  'ability_world_title',
  'ability_world_desc',
  'ability_world_slogan',
  'ability_world_image1',
  'ability_world_image2',
  'ability_world_image3',
  'ability_world_image4',
  'ability_world_bg',
  // 质量体系
  'ability_qms_title',
  'ability_qms_desc',
  // 研发管理
  'ability_rd_title',
  'ability_rd_desc',
  'ability_rd_image',
  'ability_rd_desc_h5',
  // 质量管理
  'ability_qc_title',
  'ability_qc_desc',
  'ability_qc_option1',
  'ability_qc_content1',
  'ability_qc_option2',
  'ability_qc_content2',
  'ability_qc_option3',
  'ability_qc_content3',
  'ability_qc_option4',
  'ability_qc_content4',
  'ability_qc_option5',
  'ability_qc_content5',
  'ability_qc_option6',
  'ability_qc_content6',
  // h5
  'ability_innovate_01tit_h5',
  'ability_innovate_01desc_h5',
  'ability_innovate_01tab_h5',
  'ability_innovate_02tit_h5',
  'ability_innovate_02desc_h5',
  'ability_innovate_02tab_h5',
  'ability_innovate_03tit_h5',
  'ability_innovate_03desc_h5',
  'ability_innovate_03tab_h5',
  'home_global_image_text',
  'ability_rd_image_mobile',
  'ability_banner_image_mobile',
  'ability_world_word4',
  'ability_world_word3',
  'ability_world_word2',
  'ability_world_word1',
];
