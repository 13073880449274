<!--eslint-disable-->
<template>
  <div class="right_main_icon">
    <el-popover
      popper-class="footer_popover"
      placement="left"
      trigger="hover">
      <img class="footer_code" :src="attrDatas['other_bottom_official_image']"/>
      <div slot="reference" class="right_main_icon_dis mg_b">
        <img class="right_main_icon_img" src="../../../assets/images/right_icon1.png"/>
      </div>
    </el-popover>
    <div class="right_main_icon_dis" @click="onScrollTo">
      <img class="right_main_icon_img" src="../../../assets/images/right_icon2.png"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "./attrNames";

export default {
  computed: {
    ...mapState({
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
  },
  data() {
    return {};
  },
  methods: {
    onScrollTo () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
