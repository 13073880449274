<!--eslint-disable-->
<template>
  <div class="swiper-container-main2" style="position: relative;">
    <div class="swiper-container" id="swiper2">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="glory-item2">
            <img :src="`${viewPath}${item.filePath}`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import Swiper from "swiper";
import 'swiper/css/swiper.min.css';
import { mapState } from "vuex";
import pubPath from "@/libs/path";
import { findCertificates, findHonors } from "@/assets/api/apiService";

export default {
  props: {
    swiperList: Array|Object
  },
  data() {
    return {
      viewPath: pubPath.imagePath,
    };
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  async mounted() {
    await this.getCertificates();
    this.getData();
  },
  methods: {
    getData() {
      var mySwiper1 = new Swiper("#swiper2", {
        slidesPerView: 2,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
      });
    },
    async getCertificates () {
      let res = await findCertificates({type: 1});
      this.swiperList = res.data || [];
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container-main2 {
  margin-top: 1rem;
  padding: 1rem 0;
  background: #FFFFFF;
  .swiper-container {
    height: 100%;
  }
}
.glory-item2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: 0.5rem 0.5rem;
    width: 80%;
  }
}
</style>
