<!--eslint-disable-->
<template>
  <div class="custom-content">
    <div class="custom-content-title mgt-20">
      <div class="custom-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_partner_title1']}}</div>
      <div class="custom-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_partner_desc1']}}</div>
    </div>
    <div class="custom-content-img">
      <div class="image-wrapper" v-for="(image, index) in list1" :key="index">
        <img :src="`${viewPath}${image.logoFilePath}`" alt="Image" class="image" />
      </div>
    </div>

    <div class="custom-content-title mgt-20">
      <div class="custom-content-title1">{{attrDatas['about_partner_title3']}}</div>
      <div class="custom-content-title2">{{attrDatas['about_partner_desc3']}}</div>
    </div>
    <div class="custom-content-img">
      <div class="image-wrapper" v-for="(image, index) in list3" :key="index">
        <img :src="`${viewPath}${image.logoFilePath}`" alt="Image" class="image" />
      </div>
    </div>

    <div class="custom-content-title mgt-20">
      <div class="custom-content-title1">{{attrDatas['about_partner_title2']}}</div>
      <div class="custom-content-title2">{{attrDatas['about_partner_desc2']}}</div>
    </div>
    <div class="custom-content-img mg-b-30">
      <div class="image-wrapper" v-for="(image, index) in list2" :key="index">
        <img :src="`${viewPath}${image.logoFilePath}`" alt="Image" class="image" />
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";
import { findPartners } from "@/assets/api/apiService";
import pubPath from "@/libs/path";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      viewPath: pubPath.imagePath,
      list1: [],
      list2: [],
      list3: [],
    }
  },
  mounted () {
    this.getPartners();
  },
  methods: {
    async getPartners () {
      let res = await findPartners();
      const temp = res.data || [];
      this.list1 = temp.filter(it => +it.type === 1);
      this.list2 = temp.filter(it => +it.type === 2);
      this.list3 = temp.filter(it => +it.type === 3);
    },
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
