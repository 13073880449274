<!--eslint-disable-->
<template>
  <div class="m_investor_main">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['investor_banner_image_mobile']" src="../../../assets/images/investor/m_banner.png"/>
      <img v-if="attrDatas['investor_banner_image_mobile']" :src="attrDatas['investor_banner_image_mobile']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
        >
          {{currentMenu[`menuName${attributeLang}`]}}
        </div>
        <div class="m_head_container_title_2">
          {{attrDatas['investor_banner_title']}}
        </div>
      </div>
    </div>
    <div class="productm_content_container_title m_first_content m_first_header">{{titleData[`menuName${attributeLang}`]}}</div>
    <div class="m_investor_container">
      <CompanyStructure v-if="+$route.query.type === 1" />
      <InvestorHotline v-if="+$route.query.type === 2" />
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage, DateFormat } from "@/libs/tool";
import { attrs } from "../attrNames";
import MNoData from "@/components/MNoData";
import CompanyStructure from './CompanyStructure/index';
import InvestorHotline from './InvestorHotline/index';
export default {
  components: {
    MNoData,
    InvestorHotline,
    CompanyStructure
  },
  metaInfo: {
    title: '投资者关系 Soling | SOLING 索菱股份有限公司官网 车联网硬件及软件服务提供商，互联网智能车机提供者',
    meta: [
      { name: 'description', content: '索菱（SOLING）股份有限公司，车联网硬件及软件服务提供商，互联网智能车机提供者' },
      { name: 'keywords', content: '索菱 车联网 导航 车载 互联网智能车机' },
      // 其他 meta 标签
    ],
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    currentMenu () {
      return this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
    },
    titleData () {
      const data = [
        {id: 1, name: '董事会成员'},
        {id: 2, name: '投资者关系'},
      ];
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      const child =  res.child || data;
      return child.find(c => +c.childIndex === +this.$route.query.type) || {}
    },
  },
  data () {
    return {
      newsList: [],
      tabActive: +this.$route.query.type || 1,
    }
  },
  mounted () {
  },
  methods: {
    DateFormat,
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
