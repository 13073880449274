<!--eslint-disable-->
<template>
  <div class="m_overview_content">
    <div class="productm_content_container_title m_first_content m_first_header">{{attrDatas['product_01_world_title']}}</div>
    <div class="m_overview_product">
      <div class="m_overview_product_item"
           :class="{'flex_direction_reverse': index % 2 !== 0}"
           v-for="(t, index) in tabData">
<!--        <div class="m_overview_more" @click="onJump(t.childIndex)">-->
<!--          <img src="../../../../../assets/images/product/m_more3.png"/>-->
<!--        </div>-->
        <div class="m_overview_product_content">
          <div class="m_overview_product_content_title">
            {{t[`menuName${attributeLang}`]}}
          </div>
          <div class="m_overview_product_content_desc">
            <div class="m_overview_product_content_desc_item" v-for="d in types[t.id]">
              <div class="pro-circular"></div>
              <p>{{d[`categoryName${attributeLang}`]}}</p>
            </div>
          </div>
        </div>
        <div class="m_overview_product_bj">
          <img :src="getImg(index + 1)"/>
        </div>
      </div>
    </div>
    <div class="m_overview_strategy">
<!--      <img src="../../../../../assets/images/product/m_product.png"/>-->
      <img v-if="attrDatas['product_01_contact_bgpic_h5']" :src="attrDatas['product_01_contact_bgpic_h5']"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../../attrNames";
import m_overview1Png from "@/assets/images/product/m_overview1.jpg";
import m_overview2Png from "@/assets/images/product/m_overview2.jpg";
import m_overview3Png from "@/assets/images/product/m_overview3.jpg";
import m_overview4Png from "@/assets/images/product/m_overview4.jpg";
import m_overview5Png from "@/assets/images/product/m_overview5.jpg";

export default {
  props: {
    proType: Array|Object
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      const temp = res.child || [];
      return temp.filter((t, i) => i !== 0) || [];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    types () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      const child = res.child || [];
      let data = {};
      if (child && child[1]) {
        data[child[1].id] = this.proType.filter(p => p.moduleId === child[1].id) || [];
        data[child[2].id] = this.proType.filter(p => p.moduleId === child[2].id) || [];
        data[child[3].id] = this.proType.filter(p => p.moduleId === child[3].id) || [];
        data[child[4].id] = this.proType.filter(p => p.moduleId === child[4].id) || [];
        data[child[5].id] = this.proType.filter(p => p.moduleId === child[5].id) || [];
      }
      return data;
    },
  },
  methods: {
    onJump (index) {
      this.$router.push({name: 'Product', query: {type: index}});
    },
    getImg (type) {
      if (type === 1) return m_overview1Png;
      if (type === 2) return m_overview2Png;
      if (type === 3) return m_overview3Png;
      if (type === 4) return m_overview4Png;
      if (type === 5) return m_overview5Png;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
