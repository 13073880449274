<!--eslint-disable-->
<template>
  <div class="m_news_main">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['information_mobile_banner_image']" src="../../../assets/images/news/m_news_banner.png"/>
      <img v-if="attrDatas['information_mobile_banner_image']" :src="attrDatas['information_mobile_banner_image']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
        >
          {{currentMenu[`menuName${attributeLang}`]}}
        </div>
        <div class="m_head_container_title_2">
          {{attrDatas['information_banner_title']}}
        </div>
      </div>
    </div>
    <div class="m_news_container">
      <div class="m_news_header m_first_content m_first_header">
        <div class="m_news_header_title">
          <div
            v-for="(it, index) in tabData"
            @click="onChangeTab(it)"
            :class="{'active_news': tabActive === +it.childIndex}"
            class="m_news_header_title_item">
            {{it[`menuName${attributeLang}`]}}
            <div v-if="tabActive === +it.childIndex" class="m_active_line"></div>
          </div>
        </div>
      </div>
      <div class="m_news_main" v-loading="loading">
        <div
          v-if="newsList && newsList.length"
          class="m_news_content" v-for="news in newsList"
          @click="onDetail(news)"
        >
          <div class="m_news_content_text">
            <div class="m_news_content_text_title">
              {{news.newsTitle}}
            </div>
            <div class="m_news_content_text_date">
              {{DateFormat(news.publishTime, 'yyyy-MM-dd')}}
            </div>
          </div>
          <div class="m_news_content_img">
            <img class="news-content-row-logo" :src="`${viewPath}${news.imageFilePath}`" />
          </div>
        </div>
        <MNoData v-if="!(newsList && newsList.length) && !loading"/>
        <div v-if="newsList && newsList.length" class="m_news_page">
          <div v-if="pageData.totalPages > 1" class="" @click="changePage(1)">首页</div>
          <div v-if="pageData.pageNum !== 1" class="" @click="changePage(2)">上一页</div>
          <div v-if="pageData.totalPages > 1 && pageData.pageNum !== pageData.totalPages" class="" @click="changePage(3)">下一页</div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage, DateFormat } from "@/libs/tool";
import { attrs } from "../attrNames";
import { pageNews } from "@/assets/api/apiService";
import MNoData from "@/components/MNoData";
import pubPath from "@/libs/path";
export default {
  components: {
    MNoData
  },
  metaInfo: {
    title: '资讯中心 Soling | SOLING 索菱股份有限公司官网 车联网硬件及软件服务提供商，互联网智能车机提供者',
    meta: [
      { name: 'description', content: '索菱（SOLING）股份有限公司，车联网硬件及软件服务提供商，互联网智能车机提供者' },
      { name: 'keywords', content: '索菱 车联网 导航 车载 互联网智能车机' },
      // 其他 meta 标签
    ],
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const data = [
        {id: 1, name: '公司新闻'},
        {id: 2, name: '行业资讯'},
      ]
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child || data;
    },
    currentMenu () {
      return this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
    },
  },
  data () {
    return {
      loading: false,
      pageData: {
        totalPages: 0,
        pageNum: 1,
        pageSize: 10,
      },
      viewPath: pubPath.imagePath,
      newsList: [],
      tabActive: +this.$route.query.type || 1,
      lang_str: getStorage('soling_language', false) || navigator.language.substring(0, 2),
    }
  },
  watch: {
    language (val) {
      this.lang_str = val;
      this.getPageNews();
    }
  },
  mounted () {
    this.getPageNews();
  },
  methods: {
    DateFormat,
    onChangeTab (row) {
      this.pageData.pageNum = 1;
      this.pageData.pageSize = 10;
      this.tabActive = row.childIndex;
      this.getPageNews();
      // this.$router.push({name: 'News', query: {type: this.tabActive}});
    },
    // 获取新闻
    async getPageNews () {
      this.newsList = [];
      this.loading = true;
      const types = {zh: 1, en: 2, ja: 3};
      let res = await pageNews({
        pageNum: this.pageData.pageNum,
        pageSize: this.pageData.pageSize,
        type: this.tabActive,
        publishChannel: types[this.lang_str]
      });
      this.newsList = res.data.content;
      this.pageData.totalPages = res.data.totalPages;
      this.loading = false;
    },
    onDetail (row) {
      this.$router.push({name: 'NewsDetail', params: {id: row.id}});
    },
    changePage (type) {
      if (type === 1) this.pageData.pageNum = 1;
      if (type === 2) this.pageData.pageNum = this.pageData.pageNum - 1;
      if (type === 3) this.pageData.pageNum = this.pageData.pageNum + 1;
      this.getPageNews();
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
