<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="home-container">
      <!--顶部视频-->
      <div class="mc_a1_topbox">
        <div class="mc_a1_topbox-info animate__animated animate__fadeIn animate__delay-0.8s">
          <div class="mc_a1_topbox-info_text1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_banner_title']}}</div>
          <div class="mc_a1_topbox-info_text2">{{attrDatas['home_banner_desc']}}</div>
          <div class="mc_a1_topbox-info_text3" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_banner_small_slogan']}}</div>
        </div>
        <video autoplay muted loop :key="videoKey" id="mc_a1t_video">
          <source v-if="attrDatas['home_banner_video']" :src="attrDatas['home_banner_video']" type="video/mp4">
          <source v-if="!attrDatas['home_banner_video']" src="../../assets/images/home/Compressed.mp4" type="video/mp4">
        </video>
      </div>
      <div class="home-content">
        <!--我们能为您提供的服务-->
        <div v-show="isShow.service" class="home-service animate__animated animate__fadeIn animate__delay-0.8s" ref="homeService">
          <div class="home-service-content">
            <div class="home-service-title">
              <div class="home-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_service_title']}}</div>
              <div class="home-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_service_desc']}}</div>
            </div>
            <div class="home-service-tag animate__animated animate__fadeIn animate__delay-0.8s">
              <div
                v-if="productTabData && productTabData[1]"
                class="home-service-tag-item home-service-tag-height"
                @click="jumpProduct(2)"
              >
                <img class="service-item-icon service-svg-show" src="../../assets/images/home/service/svg/icon-znwl-nor.svg"/>
                <img class="service-item-icon service-svg-hover" src="../../assets/images/home/service/svg/icon-znwl-spe.svg"/>
                <div class="home-service-tag-item-text home-service-tag-item-text-size">
                  {{productTabData[1][`menuName${attributeLang}`]}}
                </div>
              </div>
              <div
                v-if="productTabData && productTabData[2]"
                class="home-service-tag-item home-service-tag-height"
                @click="jumpProduct(3)"
              >
                <img class="service-item-icon service-svg-show" src="../../assets/images/home/service/svg/icon-znzc-spe.svg"/>
                <img class="service-item-icon service-svg-hover" src="../../assets/images/home/service/svg/icon-znzc-nor.svg"/>
                <div class="home-service-tag-item-text home-service-tag-item-text-size">
                  {{productTabData[2][`menuName${attributeLang}`]}}
                </div>
              </div>
              <div
                v-if="productTabData && productTabData[3]"
                class="home-service-tag-item home-service-tag-height"
                @click="jumpProduct(4)"
              >
                <img class="service-item-icon service-svg-show" src="../../assets/images/home/service/svg/icon-znjs-spe.svg"/>
                <img class="service-item-icon service-svg-hover" src="../../assets/images/home/service/svg/icon-znjs-nor.svg"/>
                <div class="home-service-tag-item-text home-service-tag-item-text-size">
                  {{productTabData[3][`menuName${attributeLang}`]}}
                </div>
              </div>
              <div
                v-if="productTabData && productTabData[4]"
                class="home-service-tag-item home-service-tag-height"
                @click="jumpProduct(5)"
              >
                <img class="service-item-icon service-svg-show" src="../../assets/images/home/service/svg/icon-zncy-spe.svg"/>
                <img class="service-item-icon service-svg-hover" src="../../assets/images/home/service/svg/icon-zncy-nor.svg"/>
                <div class="home-service-tag-item-text home-service-tag-item-text-size">
                  {{productTabData[4][`menuName${attributeLang}`]}}
                </div>

              </div>
              <div
                v-if="productTabData && productTabData[5]"
                class="home-service-tag-item home-service-tag-height"
                @click="jumpProduct(6)"
              >
                <img class="service-item-icon service-svg-show" src="../../assets/images/home/service/svg/icon-syc-nor.svg"/>
                <img class="service-item-icon service-svg-hover" src="../../assets/images/home/service/svg/icon-syc-spe.png"/>
                <div class="home-service-tag-item-text home-service-tag-item-text-size">
                  {{productTabData[5][`menuName${attributeLang}`]}}
                </div>
              </div>
            </div>
          </div>
          <video autoplay muted loop :key="videoKey" id="mc_a2t_video">
            <source src="../../assets/images/home/service/soling-1.mp4" type="video/mp4">
          </video>
        </div>

        <!--研发中心-->
        <div class="home-research animate__animated animate__fadeIn animate__delay-0.5s" ref="homeStrength">
          <div class="home-research-title">
            <div class="home-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_production_title']}}</div>
            <div class="home-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_production_desc']}}</div>
          </div>
          <div class="home-research-content">
            <img v-show="isShow.strength"
                 class="strength-bj animate__animated animate__fadeInLeftBig animate__delay-0.5s"
                 src="../../assets/images/home/strength/strength.png"/>
            <div class="home-research-main">
              <div v-show="isShow.strength" class="home-research-main-text animate__animated animate__fadeInUp animate__delay-0.8s">
                <div class="home-research-main-text-sub">
                  <p class="home-research-main-text1-title home-content-title1">
                    <span>{{attrDatas['home_production_left2_title']}}</span>
                  </p>
                  <p class="home-research-main-text1-content home-content-title2">
                    {{attrDatas['home_production_left2_desc']}}
                  </p>
                </div>
              </div>
              <div class="home-research-main-pic">
                <div v-show="isShow.strength" class="strength-pic-base animate__animated animate__fadeIn animate__delay-0.8s">
                  <img src="../../assets/images/home/strength/manufacture.png"/>
                </div>
                <div class="strength-pic-address">
                  <div v-show="isShow.strength" class="strength-pic-address-item animate__animated animate__fadeInDown animate__delay-1s">
                    <img style="width: 98%;" src="../../assets/images/home/strength/address1.png"/>
                    <div class="strength-pic-address-item-text">
                      <p class="home-content-title1">{{attrDatas['home_production_right1_title']}}</p>
                      <p class="home-content-title2">{{attrDatas['home_production_right1_desc']}}</p>
                    </div>
                  </div>
                  <div v-show="isShow.strength" class="strength-pic-address-item animate__animated animate__fadeIn animate__delay-1s" style="margin-left: 2%;">
                    <img src="../../assets/images/home/strength/address2.png"/>
                    <div class="strength-pic-address-item-text">
                      <p class="home-content-title1">{{attrDatas['home_production_right2_title']}}</p>
                      <p class="home-content-title2">{{attrDatas['home_production_right2_desc']}}</p>
                    </div>
                  </div>
                  <div v-show="isShow.strength" class="strength-pic-address-item animate__animated animate__fadeInUp animate__delay-1s" style="margin-left: -5%;">
                    <img style="width: 96%;" src="../../assets/images/home/strength/address3.png"/>
                    <div class="strength-pic-address-item-text">
                      <p class="home-content-title1">{{attrDatas['home_production_right3_title']}}</p>
                      <p class="home-content-title2">{{attrDatas['home_production_right3_desc']}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--智造中心-->
        <div class="home-develop animate__animated animate__fadeIn animate__delay-0.5s">
          <div class="home-develop-title">
            <div class="home-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_develop_title']}}</div>
            <div class="home-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_develop_desc']}}</div>
          </div>
          <div class="home-develop-content" ref="homeStrength1">
            <div v-show="isShow.strength1" class="home-develop-top animate__animated animate__fadeIn animate__delay-0.5s">
              <div class="home-develop-left-logo">
                <img v-if="!attrDatas['home_develop_logo']" src="../../assets/images/home/develop/soling_logo.png" />
                <img v-if="attrDatas['home_develop_logo']" :src="attrDatas['home_develop_logo']" />
              </div>
              <div class="home-develop-right-desc">
                {{attrDatas['home_develop_maindesc']}}
              </div>
            </div>
            <div v-show="isShow.strength1" class="home-develop-body animate__animated animate__fadeIn animate__delay-1s">
              <img :src="attrDatas['home_develop_image']">
            </div>
          </div>
        </div>

        <!--全球支持-->
        <div ref="homeSupport" class="home-support">
          <div class="home-support-title">
            <div class="home-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_global_title']}}</div>
            <div class="home-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_global_desc']}}</div>
          </div>
          <div v-show="isShow.support" class="home-support-img animate__animated animate__backInLeft animate__delay-0.8s">
            <img v-if="!attrDatas['home_global_image']" class="strength-bj" src="../../assets/images/home/Global.png"/>
            <video v-if="attrDatas['home_global_image']" autoplay muted loop :key="videoKey" id="mc_a2t_video">
              <source :src="attrDatas['home_global_image']" type="video/mp4">
            </video>
            <div class="home-support-desc">
              <p>{{attrDatas['home_global_image_text']}}</p>
            </div>
          </div>
        </div>

        <!--最新动态-->
        <div ref="homeNews" class="home-news">
          <div class="home-news-title">
            <div class="home-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_dynamic_title']}}</div>
            <div class="home-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_dynamic_desc']}}</div>
          </div>
          <div class="home-news-content">
            <div class="home-news-content-menus">
              <div class="news-content-ul">
                <div class="news-content-li" v-for="(it, index) in newsTag">
                  <img v-show="newsTagActiveId === it.childIndex"
                      class="news-item-img news-active-img"
                      src="../../assets/images/home/newsMore.png"/>
                  <span @click="onChangeNewsTag(it)"
                        class="news-item-text"
                        style="cursor: pointer;"
                        :class="{'news-item-active': newsTagActiveId === +it.childIndex}">
                  {{it[`menuName${attributeLang}`]}}
                </span>
                </div>
              </div>
              <div class="news-item-footer">
                <div class="news-btn new-btn-more">
                  <a href="#" @click="jumpNewsMore()">{{attrDatas['home_dynamic_button']}}</a>
                </div>
              </div>
            </div>
            <div class="news-content-row home-news-content-height"
                 :style="!(newsList && newsList.length) ? 'justify-content: center;' : ''">
              <div v-show="isShow.news && newsList && newsList.length"
                   v-for="news in newsList"
                   @mouseover="newsHover = news.id"
                   @mouseout="newsHover = null"
                   @click="jumpNewsMore(news.id)"
                   class="news-content-row-item animate__animated animate__flipInY">
                <div class="news-content-title-card">
                  <div>
                    <div class="news-content-row-day news-day-size">{{DateFormat(news.publishTime, 'dd')}}</div>
                    <div class="news-content-row-year home-content-title2">{{DateFormat(news.publishTime, 'MM/yyyy')}}</div>
                    <div class="news-content-row-desc home-content-title2">
                      {{news.newsTitle}}
                    </div>
                    <div class="news-content-row-img">
                      <img class="news-content-row-logo" :src="`${viewPath}${news.imageFilePath}`" />
                    </div>
                  </div>
                  <img
                    class="news-content-row-go news-content-row-go1"
                    :src="newsHover === news.id ? newsPng2 : newsPng1"
                  />
                </div>
              </div>
              <NoData v-if="!(newsList && newsList.length)"/>
            </div>
          </div>
        </div>

        <!--企业文化-->
        <div ref="homeCorporate" class="home-corporate animate__animated animate__fadeIn animate__delay-1s" :style="backgroundImageStyle">
          <div class="home-corporate-img">
            <img v-if="!attrDatas['home_culture_image']" src="../../assets/images/home/qiyewenhua.png" />
            <img v-if="attrDatas['home_culture_image']" :src="attrDatas['home_culture_image']" />
          </div>
          <div class="home-corporate-title">
            <div class="home-content-title1 c-white" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_culture_title']}}</div>
            <div class="home-content-title2 c-white" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_culture_desc']}}</div>
          </div>
          <div class="home-corporate-content">
            <div class="home-corporate-content-box">
              <div class="home-trends-content-item">
                <img class="home-trends-content-item-img" src="../../assets/images/home/qiyewenhua1.png"/>
                <div class="home-corporate-content-step">.</div>
                <div class="div1 c-white div1_width">{{attrDatas['home_culture_right1_title']}}</div>
                <div class="div2 c-white div2_width">{{attrDatas['home_culture_right1_desc']}}</div>
              </div>

              <div class="home-trends-content-item mgt-100">
                <img class="home-trends-content-item-img" src="../../assets/images/home/qiyewenhua2.png"/>
                <div class="home-corporate-content-step">.</div>
                <div class="div1 c-white div1_width">{{attrDatas['home_culture_right2_title']}}</div>
                <div class="div2 c-white div2_width">{{attrDatas['home_culture_right2_desc']}}</div>
              </div>

              <div class="home-trends-content-item mgt-100">
                <img class="home-trends-content-item-img" src="../../assets/images/home/qiyewenhua3.png"/>
                <div class="home-corporate-content-step">.</div>
                <div class="div1 c-white div1_width">{{attrDatas['home_culture_right3_title']}}</div>
                <div class="div2 c-white div2_width">{{attrDatas['home_culture_right3_desc']}}</div>
              </div>

              <div class="home-trends-content-item mgt-100">
                <img class="home-trends-content-item-img" src="../../assets/images/home/qiyewenhua4.png"/>
                <div class="home-corporate-content-point">.</div>
                <div class="div1 c-white div1_width">{{attrDatas['home_culture_right4_title']}}</div>
                <div class="div2 c-white div2_width">{{attrDatas['home_culture_right4_desc']}}</div>
              </div>
            </div>
          </div>
        </div>

        <!--客户及合作伙伴-->
        <div class="home-custom" ref="homeCustomRef">
          <div class="home-custom-title">
            <div class="home-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_partner_title']}}</div>
            <div class="home-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['home_partner_desc']}}</div>
          </div>
          <img v-show="isShow.custom" class="animate__animated animate__fadeIn animate__delay-1s" v-if="attrDatas['home_partner_image']" :src="attrDatas['home_partner_image']" />
          <img v-show="isShow.custom" class="animate__animated animate__fadeIn animate__delay-1s" v-if="!attrDatas['home_partner_image']" src="../../assets/images/home/custom.png" />
        </div>
      </div>
    </div>
    <MHome v-else/>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/

import { mapState } from "vuex";
import { getAttrValue, getStorage, DateFormat } from "@/libs/tool";
import { attrs } from "./attrNames";
import { pageNews, saveBrowseHistory } from "@/assets/api/apiService";
import newsPng1 from '@/assets/images/home/news-more.png';
import newsPng2 from '@/assets/images/home/service/MORE1.png';
import MHome from './m_home/M_Home';
import pubPath from "@/libs/path";
import NoData from "@/components/NoData";

export default {
  components: {
    MHome,
    NoData
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    productTabData () {
      if (!this.menuTree) return [];
      const res = this.menuTree.find(it => it.webUrl === 'Product') || {};
      return res.child;
    },
    newsTag () {
      const data = [
        {id: 1, name: '公司新闻'},
        {id: 2, name: '行业资讯'},
      ]
      const res = this.menuTree.find(it => it.webUrl === 'News') || {};
      return res.child || data;
    },
    backgroundImageStyle() {
      if (!this.isShow.corporate) return null;
      return {
        'background-position': `0% 55%`
      };
    },
    corporateWidth () {
      const styleWidth = {zh: null, en: 'width: 660px;', ja: 'width: 580px;'};
      return styleWidth[this.language];
    }
  },
  data() {
    return {
      newsPng1: newsPng1,
      newsPng2: newsPng2,
      viewPath: pubPath.imagePath,
      videoKey: 0, // 添加 key 属性，用于触发重新渲染
      default_img: require('@/assets/images/home/service/untitled.jpg'),
      default_video_1: require('@/assets/images/home/service/service_1.mp4'),
      isShow: {
        service: false,
        strength: false,
        strength1: false,
        support: false,
        news: false,
        corporate: false,
        custom: false
      },
      newsTagActiveId: 1,
      lang_str: getStorage('soling_language', false) || navigator.language.substring(0, 2),
      newsList: [],
      newsHover: null
    };
  },
  created () {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
    this.refreshVideo();
  },
  mounted () {
    this.getPageNews();
    this.getSaveBrowseHistory();
  },
  methods: {
    getSaveBrowseHistory (language) {
      const local = {zh: 1, en: 2, ja: 3};
      const lang_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);
      saveBrowseHistory({languageType: local[language] || local[lang_str], moduleType: 1});
    },
    DateFormat,
    refreshVideo() {
      this.videoKey += 1; // 更新 key 属性以重新渲染视频组件
    },
    handleScroll () {
      if (!this.$refs.homeService) {
        return;
      }
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      // 我们能为您提供的服务 - 判断滚动条是否滚动到元素顶部
      const serviceRect = this.$refs.homeService.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, serviceRect)) this.isShow.service = true;

      // 智造中心
      const strengthRect = this.$refs.homeStrength.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, strengthRect)) this.isShow.strength = true;
      const strengthRect1 = this.$refs.homeStrength1.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, strengthRect1)) this.isShow.strength1 = true;

      // supportRect
      const supportRect = this.$refs.homeSupport.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, supportRect)) this.isShow.support = true;

      // 最新动态
      const newsRect = this.$refs.homeNews.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, newsRect)) this.isShow.news = true;

      // 企业文化
      const corporateRect = this.$refs.homeCorporate.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, corporateRect)) this.isShow.corporate = true;

      // 企业文化
      const customRect = this.$refs.homeCustomRef.getBoundingClientRect();
      if (this.isScrolledToElement(viewportHeight, customRect)) this.isShow.custom = true;

    },
    // 判断滚动条是否在某个元素上
    isScrolledToElement (viewportHeight, divRect) {
      return divRect.top + 200 <= viewportHeight && divRect.bottom >= 0;
    },
    onChangeNewsTag (row) {
      this.newsTagActiveId = +row.childIndex;
      this.getPageNews();
    },
    jumpProduct (type) {
      // this.$router.push({name: 'Product', query: {type: type + ''}});
      window.location.href = '/product' + (type ? '?type=' + type : '');
    } ,
    jumpNewsMore (id) {
      if (id) {
        this.$router.push({name: 'News', query: {type: this.newsTagActiveId + '', id: id}});
      } else {
        this.$router.push({name: 'News', query: {type: this.newsTagActiveId + ''}});
      }
    },
    // 获取新闻
    async getPageNews () {
      this.newsList = [];
      const types = {zh: 1, en: 2, ja: 3};
      let res = await pageNews({
        pageNum: 1,
        pageSize: 3,
        type: this.newsTagActiveId,
        publishChannel: types[this.lang_str]
      });
      this.newsList = res.data.content;
    },
  },
  watch: {
    language (val) {
      this.lang_str = val;
      this.getPageNews();
      if (val) this.getSaveBrowseHistory(val);
    }
  },
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
