/*eslint-disable*/
const mate = {
    state: {
        metaInfo: {
            title: "",
            keywords: "",
            description: ""
        }
    },
    mutations: {
        CHANGE_META_INFO(state, metaInfo) {
            state.metaInfo = metaInfo;
        }
    },
    actions: {
        SetMetaInfo ({commit}, data) {
            commit('CHANGE_META_INFO', data);
        }
    },
    modules: {}
};
export default mate;
