/*eslint-disable*/
export const attrs = [
  // banner区
  'investor_banner_image', // banner
  'investor_banner_title', // 标题
  'investor_banner_desc', // 描述
  'investor_banner_image_mobile', // 描述
  // 公司结构
  'investor_board_title', // 模块标题
  'investor_board_desc', // 模块描述
  // 财务报表
  'investor_finance_title', // 模块标题
  'investor_finance_desc', // 模块描述
  'investor_finance_form1', // 表格title01
  'investor_finance_form2', // 表格title02
  'investor_finance_form3', // 表格title03
  // 投资者热线
  'investor_investor_title', // 模块标题
  'investor_investor_desc', // 模块描述
  'investor_investor_name1', // 投资信息名01
  'investor_investor_value1', // 投资信息值01
  'investor_investor_name2', // 投资信息名02
  'investor_investor_value2', // 投资信息值02
  'investor_investor_name3', // 投资信息名03
  'investor_investor_value3', // 投资信息值03
  'investor_investor_name4', // 投资信息名04
  'investor_investor_value4', // 投资信息值04
  'investor_investor_name5', // 投资信息名05
  'investor_investor_value5', // 投资信息值05
  'investor_investor_name6', // 投资信息名06
  'investor_investor_value6', // 投资信息值06
  'investor_investor_name7', // 投资信息名07
  'investor_investor_value7', // 投资信息值07
  'investor_investor_name8', // 投资信息名08
  'investor_investor_value8', // 投资信息值08
  'investor_investor_name9', // 投资信息名09
  'investor_investor_value9', // 投资信息值09
  'investor_investor_name10', // 投资信息名10
  'investor_investor_value10', // 投资信息值10
  'investor_investor_name11', // 投资信息名11
  'investor_investor_value11', // 投资信息值11
  'investor_investor_name12', // 投资信息名12
  'investor_investor_value12', // 投资信息值12
  'investor_investor_name13', // 投资信息名13
  'investor_investor_value13', // 投资信息值13
];
