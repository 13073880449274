<!--eslint-disable-->
<template>
  <div class="m-navbar-head" :style="headStyle">
    <div class="header-title" :style="headBg">
      <div class="header-logo">
        <div class="header-logo-img" v-show="scrollTop === 0 && !isShowMenu && !isShowRoute">
          <img v-if="!attrDatas['other_top_image']" src="../../../../assets/images/header/logo.png"/>
          <img v-if="attrDatas['other_top_image']" :src="attrDatas['other_top_image']"/>
        </div>
        <div class="header-logo-img" v-show="scrollTop !== 0 || isShowMenu || isShowRoute">
          <img v-if="!attrDatas['other_top_image2']" src="../../../../assets/images/header/logo_2.png"/>
          <img v-if="attrDatas['other_top_image2']" :src="attrDatas['other_top_image2']"/>
        </div>
        <div class="stock" :class="{'stock_s': scrollTop !== 0 || isShowMenu || isShowRoute}">{{attrDatas['other_top_code']}}</div>
      </div>
      <div class="header-menu">
        <img class="menu_img" v-show="scrollTop === 0 && !isShowMenu && !isShowRoute" @click="showMenu" src="../../../../assets/images/header/ic_menu.png" />
        <img class="menu_img" v-show="(scrollTop !== 0 || isShowRoute) && !isShowMenu" @click="showMenu" src="../../../../assets/images/header/ic_menu_2.png" />
        <img class="img_close" v-show="isShowMenu" @click="showMenu" src="../../../../assets/images/header/ic_menu_close.png" />
      </div>
    </div>
    <div class="content_menu" :style="contentMenuHeight">
      <el-menu
        v-show="isShowMenu"
        :unique-opened="true"
        :default-active="activeIndex"
        class="content_menu_main">
        <template v-for="menu in treeData" :keys="menu.webUrl">
          <el-menu-item v-if="!menu.child || ['Home', 'Capacity', 'Manufacturing'].includes(menu.webUrl)" :index="menu.webUrl" @click="onJump(menu.webUrl)">
            <div class="m_menu_div">
              <a href="#"
                 class="m_menu_a"
                 :class="{'menu-active': activeIndex === menu.webUrl}">
                {{menu[`menuName${attributeLang}`]}}
              </a>
            </div>
          </el-menu-item>
          <el-submenu v-if="menu.child && !['Home', 'Capacity', 'Manufacturing'].includes(menu.webUrl)" popper-class="subClass" :popper-append-to-body="false" :index="menu.webUrl">
            <template slot="title">
              <div class="m_menu_div">
                <a href="#"
                   class="m_menu_a"
                   :class="{'menu-active': activeIndex === menu.webUrl}">
                  {{menu[`menuName${attributeLang}`]}}
                </a>
              </div>
            </template>
            <template>
              <div v-for="c in menu.child" :keys="c.webUrl">
                <el-menu-item @click="onJump(menu.webUrl, c.childIndex)">
                  <div class="m_menu_div" style="padding-left: 1.8rem;">
                    <a class="m_menu_a" href="#">{{c[`menuName${attributeLang}`]}}</a>
                  </div>
                </el-menu-item>
              </div>
            </template>
          </el-submenu>
        </template>
      </el-menu>
      <div class="header-lang" v-show="isShowMenu">
        <div
          class="header-lang-item"
          :class="{'active': language === lang.value}"
          @click="onChangeLang(lang)"
          v-for="lang in languageData">
          {{lang.label}}
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from 'vuex';
import { attrs } from "../attrNames";
import { getAttrValue } from "@/libs/tool";
export default {
  name: 'navbarHead',
  data() {
    return {
      isShowRoute: false,
      isShowMenu: false,
      activeIndex: this.$route.name,
      screenWidth: null,
      scrollTop: 0,
      languageData: [
        {value: 'zh', label: '中文', code: ''},
        {value: 'en', label: 'English', code: ''},
        {value: 'ja', label: '日本語', code: ''}
      ]
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    treeData () {
      return this.menuTree;
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    languageLabel () {
      const res = {zh: '简体中文', en: 'English', ja: '日本語'};
      return res[this.language];
    },
    contentMenuHeight () {
      if (!this.isShowMenu) {
        return 'height: 0px;';
      } else {
        return 'height: calc(100% - 1.8rem);padding-bottom: 1rem;';
      }
    },
    headStyle () {
      if (this.isShowMenu) return 'height: 100%;';
      else return '';
    },
    headBg () {
      if (this.isShowMenu || this.isShowRoute) return 'background: #FFFFFF;box-shadow: 0px 0.1rem 0.3rem 0px rgba(0,0,0,0.07);z-index: 999;';
      if (this.scrollTop === 0 ) {
        return 'background: rgba(0,0,0,0);';
      } else {
        return 'background: #FFFFFF;box-shadow: 0px 0.1rem 0.3rem 0px rgba(0,0,0,0.07);';
      }
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  created () {
    if (typeof window !== 'undefined') {
      this.screenWidth = window.innerWidth;
      window.addEventListener('resize', this.resize)
    }
  },
  mounted () {
    if (['NewsDetail'].includes(this.$route.name)) this.isShowRoute = true;
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll () {
      // 处理滚动事件的逻辑
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    onJump (name, type) {
      this.activeIndex = name;
      const doc = document.getElementsByTagName('html');
      doc[0].style['overflow-y'] = 'auto';
      if (this.$route.name !== name) {
        this.$router.push({ name: name, query: {type: type} });
      } else {
        this.$router.replace({ name: name, query: {type: type}, params: { key: Date.now() } });
        window.location.reload();
      }
    },
    onChangeLang (lang) {
      this.$store.dispatch('setLanguage',lang.value);
      window.location.href = '/home';
    },
    showMenu () {
      this.isShowMenu = !this.isShowMenu;
      const doc = document.getElementsByTagName('html');
      if (this.isShowMenu) {
        doc[0].style['overflow-y'] = 'hidden';
      } else {
        doc[0].style['overflow-y'] = 'auto';
      }
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.body.removeEventListener('scroll', this.handleScroll);
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
