<!--eslint-disable-->
<template>
  <div class="m_ability">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['produce_banner_image_mobile']" src="../../../assets/images/capacity/m_banner.png"/>
      <img v-if="attrDatas['produce_banner_image_mobile']" :src="attrDatas['produce_banner_image_mobile']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
        >
          {{currentMenu[`menuName${attributeLang}`]}}
        </div>
        <div class="m_head_container_title_2">
          {{attrDatas['produce_banner_title']}}
        </div>
      </div>
    </div>
    <div class="m_ability_container">
      <div class="m_ability_container_title m_first_content m_first_header">
        {{attrDatas['produce_ability_title1']}}
      </div>
      <div class="m_intelligent_content">
        <div class="box1">
          <div class="box_child box_child_bj1">
            <div class="box_content_desc">
              <div class="box_content_desc_t">{{attrDatas['produce_ability_opition1']}}</div>
              <div class="box_content_desc_bj"></div>
            </div>
            <div class="box_content_title">
              {{attrDatas['produce_ability_content1']}}
            </div>
          </div>
          <div class="spacer"></div>
          <div class="box_child box_child_bj2">
            <div class="box_content_desc">
              <div class="box_content_desc_t">{{attrDatas['produce_ability_opition2']}}</div>
              <div class="box_content_desc_bj"></div>
            </div>
            <div class="box_content_title">
              {{attrDatas['produce_ability_content2']}}
            </div>
          </div>
          <div class="spacer"></div>
          <div class="box_child box_child_bj3">
            <div class="box_content_desc">
              <div class="box_content_desc_t">{{attrDatas['produce_ability_opition3']}}</div>
              <div class="box_content_desc_bj"></div>
            </div>
            <div class="box_content_title">
              {{attrDatas['produce_ability_content3']}}
            </div>
          </div>
        </div>

        <div class="bottom_box">
          <div class="box2">
            <div class="box_child_2 box_child_bj4">
              <div class="box_content_desc">
                <div class="box_content_desc_t">{{attrDatas['produce_ability_opition4']}}</div>
                <div class="box_content_desc_bj"></div>
              </div>
              <div class="box_content_title">
                {{attrDatas['produce_ability_content4']}}
              </div>
            </div>
            <div class="box_child_2 box_child_bj5">
              <div class="box_content_desc">
                <div class="box_content_desc_t">{{attrDatas['produce_ability_opition5']}}</div>
                <div class="box_content_desc_bj"></div>
              </div>
              <div class="box_content_title">
                {{attrDatas['produce_ability_content5']}}
              </div>
            </div>
          </div>
          <div class="box3">
            <div class="box_content_desc box3_desc">
              <div class="box_content_desc_t">{{attrDatas['produce_ability_opition6']}}</div>
              <div class="box_content_desc_bj"></div>
            </div>
            <div class="box_content_title box3_title">
              {{attrDatas['produce_ability_content6']}}
            </div>
          </div>
        </div>
      </div>

      <div class="m_ability_container_title mg-t">{{attrDatas['produce_ability_title2']}}</div>
      <div class="m_ability_content">
        <div class="m_ability_content_item" v-for="i in 6">
          <div class="m_ability_content_item_title">
            <img :src="getPng(i)"/>
            <span>{{attrDatas[`produce_ability_lab_name${i}`]}}</span>
          </div>
          <div class="m_ability_content_item_desc" v-if="attrDatas[`produce_ability_lab_desc${i}`]">
            <p
              v-for="s in attrDatas[`produce_ability_lab_desc${i}`].split('\n')">
              {{s}}
            </p>
          </div>
        </div>
      </div>

      <div ref="identificationContent" class="m_ability_container_title mg-t">{{attrDatas['produce_experiment_title']}}</div>
      <div class="m_identification_content">
        <div class="m_identification_main">
          <div class="m_identification_main_item">
            <div class="m_identification_main_item_title">{{attrDatas['produce_experiment_option1']}}</div>
            <div class="m_identification_main_item_desc">{{attrDatas['produce_experiment_content1']}}</div>
          </div>
          <div class="m_identification_main_item">
            <div class="m_identification_main_item_title">{{attrDatas['produce_experiment_option2']}}</div>
            <div class="m_identification_main_item_desc">{{attrDatas['produce_experiment_content2']}}</div>
          </div>
          <div class="m_identification_main_item">
            <div class="m_identification_main_item_title">{{attrDatas['produce_experiment_option3']}}</div>
            <div class="m_identification_main_item_desc">{{attrDatas['produce_experiment_content3']}}</div>
          </div>
          <div class="m_identification_main_item">
            <div class="m_identification_main_item_title">{{attrDatas['produce_experiment_option4']}}</div>
            <div class="m_identification_main_item_desc">{{attrDatas['produce_experiment_content4']}}</div>
          </div>
          <div class="m_identification_main_img">
            <img v-if="!attrDatas['produce_experiment_image']" src="../../../assets/images/manufacturing/m_cnas.png"/>
            <img v-if="attrDatas['produce_experiment_image']" :src="attrDatas['produce_experiment_image']"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../attrNames";
import cemPng1 from "@/assets/images/manufacturing/m_cem1.png";
import cemPng2 from "@/assets/images/manufacturing/m_cem2.png";
import cemPng3 from "@/assets/images/manufacturing/m_cem3.png";
import cemPng4 from "@/assets/images/manufacturing/m_cem4.png";
import cemPng5 from "@/assets/images/manufacturing/m_cem5.png";
import cemPng6 from "@/assets/images/manufacturing/m_cem6.png";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig,
      menuTree: state => state.publicData.menuTree,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    currentMenu () {
      return this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
    }
  },
  mounted () {
    if (+this.$route.query.type === 2) {
      this.$nextTick(() => {
        this.scrollIntoView();
      })
    }
  },
  methods: {
    scrollIntoView () {
      return this.$refs.identificationContent.scrollIntoView({ behavior: 'smooth' });
    },
    getPng (i) {
      if (+i === 1) return cemPng1;
      if (+i === 2) return cemPng2;
      if (+i === 3) return cemPng3;
      if (+i === 4) return cemPng4;
      if (+i === 5) return cemPng5;
      if (+i === 6) return cemPng6;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
