<!--eslint-disable-->
<template>
  <div class="m-footer-main">
    <div class="m-footer-main-title">
      <div class="m-footer-title-logo">
        <img v-if="!attrDatas['other_bottom_image']" src="../../../../assets/images/footer/footer-logo.png"/>
        <img v-if="attrDatas['other_bottom_image']" :src="attrDatas['other_bottom_image']"/>
      </div>
      <div class="m-footer-title-icon">
        <el-popover
          popper-class="m_footer_popover"
          placement="top"
          trigger="click">
          <img class="m_footer_code" :src="attrDatas['other_bottom_microblog_image']"/>
          <img slot="reference" style="margin-right: 0.6rem;" src="../../../../assets/images/footer/weibo.svg"/>
        </el-popover>
        <el-popover
          popper-class="m_footer_popover"
          placement="top"
          trigger="click">
          <img class="m_footer_code" :src="attrDatas['other_bottom_official_image']"/>
          <img slot="reference" style="margin-right: 0.6rem;" src="../../../../assets/images/footer/weixin.svg"/>
        </el-popover>
      </div>
    </div>
    <div class="m-footer-main-content">
      <div class="m-footer-content-item">
        <div class="m-footer-content-item-title">
          {{attrDatas['other_bottom_phone_name1']}}
        </div>
        <div class="m-footer-content-item-desc">
          <div class="m-footer-content-item-desc-item m_phone_w1">{{attrDatas['other_bottom_phone_value1']}}</div>
          <div class="m-footer-content-item-desc-item m_phone_w2">{{attrDatas['other_bottom_phone_value2']}}</div>
          <div class="m-footer-content-item-desc-item m_phone_w1">{{attrDatas['other_bottom_phone_value3']}}</div>
          <div class="m-footer-content-item-desc-item m_phone_w2">{{attrDatas['other_bottom_phone_value4']}}</div>
        </div>
      </div>
      <div class="m-footer-content-item">
        <div class="m-footer-content-item-title">
          {{attrDatas['other_bottom_email_name1']}}
        </div>
        <div class="m-footer-content-item-desc">
          {{attrDatas['other_bottom_email_value1']}}
        </div>
      </div>
    </div>
    <div class="m-footer-record-desc">
      <div class="m-footer-record-desc">
        <div class="m-footer-record-desc1">
          <a class="footer-right-content" @click="goLaw">{{attrDatas['other_bottom_law']}}</a>
          <a @click="goPrivacy">{{attrDatas['other_bottom_privacy']}}</a>
        </div>
        <div class="m-footer-record-desc2">
          <p @click="toFilings">{{attrDatas['other_bottom_copyright']}}</p>
          <p @click="toFilings">{{attrDatas['other_bottom_filings']}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "../attrNames";

export default {
  name: 'Footer',
  components: {},
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    treeData () {
      if (this.language === 'zh') {
        return this.menuTree.filter(menu => menu.webUrl !== 'Home');
      } else {
        return this.menuTree.filter(menu => menu.webUrl !== 'Home').slice(0, this.menuTree.length - 2);
      }
    },
    menuData () {
      return this.menuTree.filter(menu => menu.webUrl !== 'Home');
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
  },
  data() {
    return {};
  },
  methods: {
    onJump (name, type) {
      if (this.$route.name !== name) {
        this.$router.push({ name: name, query: {type: type} });
      } else {
        this.$router.replace({ name: name, query: {type: type}, params: { key: Date.now() } });
      }
    },
    toFilings () {
      window.open('https://beian.miit.gov.cn/');
    },
    goLaw () {
      window.open('/law.html');
    },
    goPrivacy () {
      window.open('/privacy.html');
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
