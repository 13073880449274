<!--eslint-disable-->
<template>
  <div class="m_home-container">
    <!--顶部banner-->
    <div class="m_head_container">
      <img v-if="!attrDatas['home_banner_video_h5']" src="../../../assets/images/home/bg.jpg"/>
      <img v-if="attrDatas['home_banner_video_h5']" :src="attrDatas['home_banner_video_h5']"/>
      <div class="m_head_container_title animate__animated animate__fadeIn animate__delay-1s">
        <div
          class="m_head_container_title_1"
          :class="{'letterSpacing_en': language === 'en'}"
        >
          {{attrDatas['home_banner_title']}}
        </div>
        <div class="m_head_container_title_3"
             :class="{'letterSpacing_en': language === 'en'}">
          {{attrDatas['home_banner_small_slogan']}}
        </div>
      </div>
    </div>
    <!--我们能为您提供的服务-->
    <div class="m_product_container m_first_content">
      <div class="m_content_header m_first_header">
        <div class="m_content_header_title1">{{attrDatas['home_service_title']}}</div>
      </div>
      <div class="m_product_content">
        <div class="m_product_content_row">
          <div class="m_product_content_item" v-if="productTabData && productTabData[1]" @click="jumpProduct(2)">
            <img src="../../../assets/images/home/product_1.png"/>
            <div class="m_product_content_text">{{productTabData[1][`menuName${attributeLang}`]}}</div>
          </div>
          <div class="m_product_content_item" v-if="productTabData && productTabData[2]" @click="jumpProduct(3)">
            <img src="../../../assets/images/home/product_2.png"/>
            <div class="m_product_content_text">{{productTabData[2][`menuName${attributeLang}`]}}</div>
          </div>
          <div class="m_product_content_item" v-if="productTabData && productTabData[3]" @click="jumpProduct(4)">
            <img src="../../../assets/images/home/product_3.png"/>
            <div class="m_product_content_text">{{productTabData[3][`menuName${attributeLang}`]}}</div>
          </div>
        </div>
        <div class="m_product_content_row mg-t">
          <div class="m_product_content_item" v-if="productTabData && productTabData[4]" @click="jumpProduct(5)">
            <img src="../../../assets/images/home/product_4.png"/>
            <div class="m_product_content_text">{{productTabData[4][`menuName${attributeLang}`]}}</div>
          </div>
          <div class="m_product_content_item" v-if="productTabData && productTabData[5]" @click="jumpProduct(6)">
            <img src="../../../assets/images/home/product_5.png"/>
            <div class="m_product_content_text">{{productTabData[5][`menuName${attributeLang}`]}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--智造中心-->
    <div class="m_strength_container">
      <div class="m_content_header">
        <div class="m_content_header_title1">{{attrDatas['home_production_title']}}</div>
      </div>
      <div class="m_strength_content" style="margin-bottom: 0.5rem">
        <div class="m_strength_col2">
          <div class="m_strength_row">
            <div class="m_strength_row_title">
              {{attrDatas['home_production_right1_title']}}
            </div>
            <div class="m_strength_row_desc">
              {{attrDatas['home_production_right1_desc']}}
            </div>
          </div>
          <div class="m_strength_row ma-g-20">
            <div class="m_strength_row_title">
              {{attrDatas['home_production_right2_title']}}
            </div>
            <div class="m_strength_row_desc">
              {{attrDatas['home_production_right2_desc']}}
            </div>
          </div>
          <div class="m_strength_row">
            <div class="m_strength_row_title">
              {{attrDatas['home_production_right3_title']}}
            </div>
            <div class="m_strength_row_desc">
              {{attrDatas['home_production_right3_desc']}}
            </div>
          </div>
        </div>
      </div>

      <div class="m_content_header">
        <div class="m_content_header_title1">{{attrDatas['home_develop_title']}}</div>
      </div>
      <div class="m_develop_main">
        <div class="m_develop_desc">{{attrDatas['home_develop_maindesc']}}</div>
        <div class="m_develop_desc_img">
          <img src=".././../../assets/images/home/m_develop.png"/>
        </div>
      </div>
    </div>
    <!--全球支持-->
    <div class="m_global_container">
      <div class="m_content_header">
        <div class="m_content_header_title1">{{attrDatas['home_global_title']}}</div>
      </div>
      <div class="m_global_content">
        <img src="../../../assets/images/home/m_global.gif"/>
        <div class="m_global_content_desc">
          <p>{{attrDatas['home_global_image_text']}}</p>
        </div>
      </div>
    </div>
    <!--资讯中心-->
    <div class="m_news_container">
      <div class="m_news_header">
        <div class="m_news_header_title">
          <div
            v-for="(it, index) in newsTag"
            @click="onChangeNewsTag(it)"
            :class="{'active_news': newsTagActiveId === +it.childIndex}"
            class="m_news_header_title_item">
            {{it[`menuName${attributeLang}`]}}
            <div v-if="newsTagActiveId === +it.childIndex" class="m_active_line"></div>
          </div>
        </div>
        <div class="m_news_header_more" @click="jumpNewsMore()">
          {{attrDatas['home_dynamic_button']}} >
        </div>
      </div>
      <div class="m_news_main">
        <div
          v-if="newsList && newsList.length"
          class="m_news_content"
          @click="jumpNewsMore(news.id)"
          v-for="news in newsList">
          <div class="m_news_content_text">
            <div class="m_news_content_text_title">
              {{news.newsTitle}}
            </div>
            <div class="m_news_content_text_date">
              {{DateFormat(news.publishTime, 'yyyy-MM-dd')}}
            </div>
          </div>
          <div class="m_news_content_img">
            <img class="news-content-row-logo" :src="`${viewPath}${news.imageFilePath}`" />
          </div>
        </div>
        <MNoData v-if="!(newsList && newsList.length)" :height="'20vh'" />
      </div>
    </div>
    <!--企业文化-->
    <div class="m_corporate_container">
      <div class="m_content_header" style="border: 0px solid;">
        <div class="m_content_header_title1">{{attrDatas['home_culture_title']}}</div>
      </div>
      <div class="m_corporate_content">
        <div>
          <div class="m_corporate_content_item">
            <img src="../../../assets/images/home/m_qiyewenhua1.png"/>
            <div class="m_corporate_content_text">
              <div class=m_corporate_content_text-title>{{attrDatas['home_culture_right1_title']}}</div>
              <div class=m_corporate_content_text-desc>{{attrDatas['home_culture_right1_desc']}}</div>
            </div>
          </div>
          <div class="m_corporate_content_item">
            <img src="../../../assets/images/home/m_qiyewenhua2.png"/>
            <div class="m_corporate_content_text">
              <div class=m_corporate_content_text-title>{{attrDatas['home_culture_right2_title']}}</div>
              <div class=m_corporate_content_text-desc>{{attrDatas['home_culture_right2_desc']}}</div>
            </div>
          </div>
          <div class="m_corporate_content_item">
            <img src="../../../assets/images/home/m_qiyewenhua3.png"/>
            <div class="m_corporate_content_text">
              <div class=m_corporate_content_text-title>{{attrDatas['home_culture_right3_title']}}</div>
              <div class=m_corporate_content_text-desc>{{attrDatas['home_culture_right3_desc']}}</div>
            </div>
          </div>
          <div class="m_corporate_content_item">
            <img src="../../../assets/images/home/m_qiyewenhua4.png"/>
            <div class="m_corporate_content_text">
              <div class=m_corporate_content_text-title>{{attrDatas['home_culture_right4_title']}}</div>
              <div class=m_corporate_content_text-desc>{{attrDatas['home_culture_right4_desc']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--客户及合作伙伴-->
    <div class="m_custom_container">
      <div class="m_content_header" style="border: 0px solid;">
        <div class="m_content_header_title1">{{attrDatas['home_partner_title']}}</div>
      </div>
      <div class="m_custom_content">
        <img v-if="attrDatas['home_partner_image_h5']" :src="attrDatas['home_partner_image_h5']" />
        <img v-if="!attrDatas['home_partner_image_h5']" src="../../../assets/images/home/m_custom.png" />
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/

import { mapState } from "vuex";
import { getAttrValue, getStorage, DateFormat } from "@/libs/tool";
import { attrs } from "../attrNames";
import { pageNews } from "@/assets/api/apiService";
import newsPng1 from '@/assets/images/home/news-more.png';
import newsPng2 from '@/assets/images/home/service/MORE1.png';
import MNoData from "@/components/MNoData";
import pubPath from "@/libs/path";

export default {
  components: {
    MNoData
  },
  metaInfo: {
    title: '首页 Soling | SOLING 索菱股份有限公司官网 车联网硬件及软件服务提供商，互联网智能车机提供者',
    meta: [
      { name: 'description', content: '索菱（SOLING）股份有限公司，车联网硬件及软件服务提供商，互联网智能车机提供者' },
      { name: 'keywords', content: '索菱 车联网 导航 车载 互联网智能车机' },
      // 其他 meta 标签
    ],
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    productTabData () {
      if (!this.menuTree) return [];
      const res = this.menuTree.find(it => it.webUrl === 'Product') || {};
      return res.child;
    },
    newsTag () {
      const data = [
        {id: 1, name: '公司新闻'},
        {id: 2, name: '行业资讯'},
      ]
      const res = this.menuTree.find(it => it.webUrl === 'News') || {};
      return res.child || data;
    }
  },
  data() {
    return {
      newsPng1: newsPng1,
      newsPng2: newsPng2,
      viewPath: pubPath.imagePath,
      videoKey: 0, // 添加 key 属性，用于触发重新渲染
      isShow: {
        service: false,
        strength: false,
        support: false,
        news: false,
        corporate: false,
      },
      newsTagActiveId: 1,
      lang_str: getStorage('soling_language', false) || navigator.language.substring(0, 2),
      newsList: [],
      newsHover: null
    };
  },
  created () {
    this.refreshVideo();
  },
  mounted () {
    this.getPageNews();
  },
  methods: {
    DateFormat,
    refreshVideo() {
      this.videoKey += 1; // 更新 key 属性以重新渲染视频组件
    },
    // 判断滚动条是否在某个元素上
    isScrolledToElement (viewportHeight, divRect) {
      return divRect.top + 200 <= viewportHeight && divRect.bottom >= 0;
    },
    onChangeNewsTag (row) {
      this.newsTagActiveId = +row.childIndex;
      this.getPageNews();
    },
    jumpProduct (type) {
      this.$router.push({name: 'Product', query: {type: type + ''}});
    } ,
    jumpNewsMore (id) {
      if (id) {
        this.$router.push({name: 'NewsDetail', params: {id: id}});
      } else {
        this.$router.push({name: 'News', query: {type: this.newsTagActiveId + ''}});
      }
    },
    // 获取新闻
    async getPageNews () {
      this.newsList = [];
      const types = {zh: 1, en: 2, ja: 3};
      let res = await pageNews({
        pageNum: 1,
        pageSize: 3,
        type: this.newsTagActiveId,
        publishChannel: types[this.lang_str]
      });
      this.newsList = res.data.content;
    },
  },
  watch: {
    language (val) {
      this.lang_str = val;
      this.getPageNews();
    }
  },
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
