<!--eslint-disable-->
<template>
  <div class="m_glory_content">
    <CarouselGloy />
    <CarouselGloy2 />
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue } from "@/libs/tool";
import { attrs } from "../../../attrNames";
import CarouselGloy from '@/components/M_CarouselGloy';
import CarouselGloy2 from '@/components/M_CarouselGloy2';

export default {
  components: {
    CarouselGloy,
    CarouselGloy2,
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
