<!--eslint-disable-->
<template>
  <div class="m-no-data" :style="style_h">
    <img src="../assets/images/no_data.png"/>
    <p class="no-data-text">No Data</p>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
export default {
  props: {
    height: String
  },
  computed: {
    style_h () {
      return `height: ${this.height}`;
    },
  }
};
</script>
<style lang="scss" scoped>
.m-no-data {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42vh;
  img {
    width: 5rem;
  }
  .no-data-text {
    position: absolute;
    top: 65%;
    color: #959595;
    font-size: 1rem;
  }
}
</style>
