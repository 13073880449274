<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="navbar-head" :style="headBg">
      <div class="header-logo">
        <div class="header-logo-img" v-show="+scrollTop === 0">
          <img v-show="!attrDatas['other_top_image']" src="../../../assets/images/header/logo.png"/>
          <img v-show="attrDatas['other_top_image']" :src="attrDatas['other_top_image']"/>
        </div>
        <div class="header-logo-img" v-show="+scrollTop !== 0">
          <img v-show="!attrDatas['other_top_image2']" src="../../../assets/images/header/logo_2.png"/>
          <img v-show="attrDatas['other_top_image2']" :src="attrDatas['other_top_image2']"/>
        </div>
        <div class="stock" :style="+scrollTop === 0 ? 'color: #fff;' : 'color: #737578;'">{{attrDatas['other_top_code']}}</div>
      </div>
      <div class="header-menu">
        <el-menu
          :default-active="activeIndex"
          :class="+scrollTop === 0 ? 'el-menu-init1' : 'el-menu-init2'"
          mode="horizontal">
          <template v-for="menu in treeData" :keys="menu.webUrl">
            <el-menu-item v-if="!menu.child || menu.webUrl === 'Home'" :index="menu.webUrl">
              <a href="#"
                 :class="{'menu-active': activeIndex === menu.webUrl}"
                 @click="onJump(menu.webUrl)"
                 :style="titleStyle">
                {{menu[`menuName${attributeLang}`]}}
              </a>
            </el-menu-item>
            <el-submenu
              :popper-append-to-body="false"
              v-if="menu.child && menu.webUrl !== 'Home'"
              popper-class="subClass"
              :index="menu.webUrl">
              <template slot="title">
                <a href="#"
                   :class="{'menu-active': activeIndex === menu.webUrl}"
                   @click="onJump(menu.webUrl, 1)"
                   :style="titleStyle">
                  {{menu[`menuName${attributeLang}`]}}
                </a>
              </template>
              <template>
                <div v-for="c in menu.child" :keys="c.webUrl">
                  <el-menu-item>
                    <a href="#" @click="onJump(menu.webUrl, c.childIndex)" :style="subTitleStyle">{{c[`menuName${attributeLang}`]}}</a>
                  </el-menu-item>
                </div>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
      <div class="header-option">
        <div class="header-option-lang">
          <img v-show="+scrollTop === 0" class="header-option-lang-img" src="../../../assets/images/header/lang.svg"/>
          <img v-show="+scrollTop !== 0" class="header-option-lang-img" src="../../../assets/images/header/lang2.svg"/>
        </div>
        <el-dropdown trigger="hover" class="header-navbar-lang" :style="+scrollTop === 0 ? 'color: #fff;' : 'color: #737578;'">
        <span class="el-dropdown-link">
          <span class="dropdown-text">{{languageLabel}}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <div v-for="(lang, index) in languageData" :keys="index" @click="onChangeLang(lang)">
              <el-dropdown-item>
                <div style="width: 100%;font-size: 12px;">{{lang.label}}</div>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <MHeader v-else />
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from 'vuex';
import { attrs } from "./attrNames";
import { getAttrValue } from "@/libs/tool";
import MHeader from './m_header/index';
export default {
  name: 'navbarHead',
  components: {
    MHeader
  },
  data() {
    return {
      activeIndex: this.$route.name,
      screenWidth: null,
      scrollTop: 0,
      languageData: [
        {value: 'zh', label: '简体中文'},
        {value: 'en', label: 'English'},
        {value: 'ja', label: '日本語'}
      ]
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    treeData () {
      return this.menuTree;
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    languageLabel () {
      const res = {zh: '简体中文', en: 'English', ja: '日本語'};
      return res[this.language];
    },
    titleStyle () {
      if (this.screenWidth > 1500) {
        return 'font-size: 16px;margin-right: 50px;';
      } else if (this.screenWidth > 1200) {
        return 'font-size: 14px;margin-right: 30px;';
      } else {
        return 'font-size: 14px;margin-right: 20px;;max-width: 120px;white-space: initial;line-height: 1;';
      }
    },
    subTitleStyle () {
      if (this.screenWidth > 1500) {
        return 'font-size: 14px;padding: 0 10px;color: #7F7F7F;'
      } else if (this.screenWidth > 1200) {
        return 'font-size: 13px;padding: 0 10px;color: #7F7F7F;';
      } else {
        return 'font-size: 12px;padding: 0 5px;color: #7F7F7F;';
      }
    },
    headBg () {
      if (this.scrollTop === 0 ) {
        return 'background: rgba(0,0,0,0);';
      } else {
        return 'background: #FFFFFF;box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.23);';
      }
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  created () {
    if (typeof window !== 'undefined') {
      this.screenWidth = window.innerWidth;
      window.addEventListener('resize', this.resize)
    }
  },
  watch: {
    menuTree (val) {
      // console.log(val)
    }
  },
  mounted () {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    resize(event) {
      if (typeof window !== 'undefined') {
        this.screenWidth = window.innerWidth;
      }
    },
    handleScroll () {
      // 处理滚动事件的逻辑
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    onJump (name, type) {
      this.activeIndex = name;
      if (this.$route.name !== name) {
        this.$router.push({ name: name, query: {type: type} });
      } else {
        this.$router.replace({ name: name, query: {type: type}, params: { key: Date.now() } });
      }
      // window.location.href = '/' + name.toLocaleLowerCase() + (type ? '?type=' + type : '');
    },
    onChangeLang (lang) {
      this.$store.dispatch('setLanguage',lang.value);
      window.location.href = '/home';
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resize);
      document.body.removeEventListener('scroll', this.handleScroll);
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
