/*eslint-disable*/
export default {
  menu: {
    home: '主页',
    test1: '测试1',
    test2: '测试2'
  },
  content: {
    home: '你好 主页',
    test1: '你好 测试1',
    test2: '你好 测试2'
  }
}
