<!--eslint-disable-->
<template>
  <div class="recruit-content">
    <div class="recruit-content-title mgt-20">
      <div class="recruit-content-title1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_join_title']}}</div>
      <div class="recruit-content-title2" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['about_join_desc']}}</div>
    </div>
    <div class="recruit-content-main">
      <el-collapse v-for="(data, index) in recruitments" :key="index" v-model="activeNames">
        <el-collapse-item :name="(index + 1) + ''">
          <div slot="title">
            <div class="recruit-main-title">
              <div class="recruit-main-title-desc">
                <img src="../../../../assets/images/about/soling-logo.png" />
                {{data[`positionName${attributeLang}`]}}
              </div>
              <div class="recruit-main-title-address">
                {{data[`address${attributeLang}`]}}
              </div>
            </div>
          </div>
          <div class="recruit-main-container">
            <div class="recruit-container-desc">
              <div class="recruit-container-icon">
                <img src="../../../../assets/images/about/ico-join1.png"/>
                <div class="recruit-container-icon-desc">{{attrDatas['about_join_duty_title']}}</div>
              </div>
              <div class="recruit-container-text">
                <div v-if="data[`duty${attributeLang}`]"
                     v-for="item in data[`duty${attributeLang}`].split('\n')"
                     class="recruit-container-text-item">
                  {{item}}
                </div>
              </div>
            </div>
            <div class="recruit-container-ask">
              <div class="recruit-container-icon">
                <img src="../../../../assets/images/about/ico-join2.png"/>
                <div class="recruit-container-icon-desc">{{attrDatas['about_join_post_title']}}</div>
              </div>
              <div class="recruit-container-text">
                <div v-if="data[`post${attributeLang}`]"
                     v-for="item in data[`post${attributeLang}`].split('\n')"
                     class="recruit-container-text-item">
                  {{item}}
                </div>
              </div>
            </div>
          </div>
          <div class="recruit-main-email recruit-content-title2">{{data[`email${attributeLang}`]}}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";
import { pageRecruitment } from "@/assets/api/apiService";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      activeNames: ['1'],
      recruitments: []
    }
  },
  mounted () {
    this.getPageRecruitment();
  },
  methods: {
    async getPageRecruitment () {
      const res = await pageRecruitment({pageNum: 1, pageSize: 100});
      this.recruitments = res.data.content;
      console.log(this.recruitments)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
