<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="news-main">
      <!--顶部banner-->
      <div class="news-banner" :style="bannerStyle">
        <div class="news-banner-info animate__animated animate__fadeIn animate__delay-0.8s">
          <div class="mc_a1_topbox-info_text1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['information_banner_title']}}</div>
          <div class="mc_a1_topbox-info_text2">{{attrDatas['information_banner_desc']}}</div>
        </div>
        <div class="news-banner-img">
          <img v-if="attrDatas['information_banner_image']" ref="imgRef" @load="handleLoad" :src="attrDatas['information_banner_image']"/>
          <img v-if="!attrDatas['information_banner_image']" src="../../assets/images/news/banner.png"/>
        </div>
        <div class="banner-tab banner-tab-media">
          <div class="banner-tab-main">
            <div
              v-for="tab in tabData"
              :keys="tab.id"
              @click="onChangeTab(tab)"
              :class="{'tab-active':tabActive === tab.childIndex}"
              class="banner-tab-content">
              {{tab[`menuName${attributeLang}`]}}
            </div>
          </div>
        </div>
      </div>
      <!--内容-->
      <div v-if="!$route.query.id">
        <News v-show="tabActive === 1"/>
        <Information v-show="tabActive === 2"/>
      </div>
      <div v-if="$route.query.id" class="news-content">
        <Detail class="animate__animated animate__fadeIn"/>
      </div>
    </div>
    <MNews v-if="isMobile"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import News from './components/News/index'
import Information from './components/Information/index'
import Detail from './components/Detail/index'
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "./attrNames";
import MNews from "./m_news/index";
import { saveBrowseHistory } from "@/assets/api/apiService";
export default {
  components: {
    News,
    Information,
    Detail,
    MNews
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    bannerStyle () {
      if (!this.bannerHeight) return;
      else return {
        height: this.bannerHeight + 'px'
      }
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const data = [
        {id: 1, name: '公司新闻'},
        {id: 2, name: '行业资讯'},
      ]
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child || data;
    },
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
      bannerHeight: 0,
    }
  },
  watch: {
    language (val) {
      if (val) this.getSaveBrowseHistory(val);
    }
  },
  mounted () {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleLoad)
    }
    this.getSaveBrowseHistory();
  },
  methods: {
    getSaveBrowseHistory (language) {
      const local = {zh: 1, en: 2, ja: 3};
      const lang_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);
      saveBrowseHistory({languageType: local[language] || local[lang_str], moduleType: 6});
    },
    onChangeTab (row) {
      this.tabActive = row.childIndex;
      this.$router.push({name: 'News', query: {type: this.tabActive}});
    },
    handleLoad () {
      this.bannerHeight = this.$refs.imgRef.clientHeight;
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleLoad);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
