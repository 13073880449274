<!--eslint-disable-->
<template>
  <div class="product-content">
    <div class="product-content-title mgt-20">
      <div class="product-content-title1" :class="{'letterSpacing_en': language === 'en'}">
        {{currentChild[`menuName${attributeLang}`]}}
      </div>
      <div
        v-if="language === 'zh'"
        class="product-content-title2"
        :class="{'letterSpacing_en': language === 'en'}">
        {{currentChild[`menuNameEn`]}}
      </div>
    </div>
    <div class="device-main2">
      <div class="device-content">
        <div class="device-left">
          <div v-for="d in devices"
               class="device-left-item"
               @click="onChangeDevice(d)"
               :class="{'device-active': activeId === d.id}">
            <img v-if="activeId === d.id" class="active-icon" src="../../../../assets/images/product/active.svg"/>
            <div v-else style="width: 30px"></div>
            <span>{{d[`categoryName${attributeLang}`]}}</span>
          </div>
        </div>
        <div class="gap"></div>
        <div class="device-right">
          <div class="device-detail-item"
               v-if="products && products.length"
               v-for="(d, index1) in products"
               :ref="`productItem${d.id}`"
               :class="index1 % 2 === 0 ? 'flex-left' : 'flex-right'">
            <div class="device-detail-desc">
              <div class="device-detail-desc-title">
                {{d[`productName${attributeLang}`]}}
              </div>
              <div class="device-detail-desc-text" v-if="d[`productIntroduce${attributeLang}`]">
                <div class="device-detail-desc-text-item"
                     v-for="(t, index) in d[`productIntroduce${attributeLang}`].split('\n')"
                     v-show="index < 7 || d.more"
                >
                  <div class="device-detail-desc-c"></div>
                  {{t}}
<!--                  <span v-for="(str, index) in t.split('：')"-->
<!--                        :class="{'fontWeight_b': index === 0 && t.split('：').length > 1}"-->
<!--                  >-->
<!--                    {{`${str}${index === t.split('：').length - 1 ? '' : '：'}`}}-->
<!--                  </span>-->
                  <div class="produce_more_icon"
                       @click="onMore(d)"
                       v-if="(!d.more && index === 6 && d[`productIntroduce${attributeLang}`].split('\n').length > 7)
                       || (d.more && index === d[`productIntroduce${attributeLang}`].split('\n').length - 1)">
                    <span class="">
                      {{d.more ? attrDatas['product_02_close'] : attrDatas['product_02_open']}}
                    </span>
                    <img :class="{'img_180': d.more}" src="../../../../assets/images/product/more_product.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="device-detail-img" :class="+d.imageShow === 1 ? `img_flex_1 ${index1 % 2 === 0 ? 'img_right' : 'img_left'}` : `img_flex_2`">
              <img :src="`${viewPath}${d.imageFilePath}`" />
            </div>
          </div>
          <no-data v-if="!(products && products.length)"></no-data>
        </div>
      </div>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";
import pubPath from "@/libs/path";
import NoData from "@/components/NoData";

export default {
  props: {
    proType: Array|Object
  },
  components: {
    NoData
  },
  computed: {
    ...mapState({
      menuTree: state => state.publicData.menuTree,
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    },
    devices () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      const child = res.child || [];
      let data = [];
      const i = +this.$route.query.type - 1;
      if (child && child[i] && this.proType) {
        data = this.proType.filter(p => p.moduleId === child[i].id) || [];
        if (data[0]) {
          this.activeId = data[0].id;
          const temp = data[0].products || [];
          this.products = temp.map(p => {
            return {
              more: false,
              ...p
            }
          });
        }
      }
      return data;
    },
    currentChild () {
      const menu = this.menuTree.find(menu => menu.webUrl === this.$route.name) || {};
      let child = {};
      if (menu.child) {
        child = menu.child.find(m => +m.childIndex === +this.$route.query.type) || {}
      }
      return child;
    },
  },
  mounted () {

  },
  data () {
    return {
      activeId: null,
      products: [],
      viewPath: pubPath.imagePath,
      moreId: null,
    }
  },
  methods: {
    onChangeDevice (row) {
      this.moreId = null;
      this.products = [];
      this.activeId = row.id;
      this.products = row.products || [];
      this.products = this.products.map(p => {
        return {
          more: false,
          ...p
        }
      });
    },
    onMore (row) {
      this.moreId = row.id;
      if (!row.more) row.more = true;
      else row.more = false;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
