<!--eslint-disable-->
<template>
  <div class="swiper-container-main4" style="position: relative;">
    <div class="swiper-container" id="swiper3">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="glory-item4">
            <img :src="`${viewPath}${item.filePath}`" />
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next1">
      <img class="swiper_img swiper_img_left" src="../assets/images/swiper_left.png"/>
    </div>
    <div class="swiper-button-prev1">
      <img class="swiper_img swiper_img_right" src="../assets/images/swiper_right.png"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/*eslint-disable*/
import Swiper from "swiper";
import 'swiper/css/swiper.min.css';
import { mapState } from "vuex";
import pubPath from "@/libs/path";

export default {
  props: {
    swiperList: Array|Object
  },
  data() {
    return {
      viewPath: pubPath.imagePath,
    };
  },
  computed: {
    ...mapState({
      language: state => state.publicData.language,
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 500)
  },
  methods: {
    getData() {
      var mySwiper1 = new Swiper("#swiper3", {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-prev1",
          prevEl: ".swiper-button-next1",
          disabledClass: 'init-button-disabled',
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">
.swiper-container-main4 {
  .swiper-container {
    height: 100%;
    background: #FFFFFF;
  }
}
.glory-item4 {
  background: #FFFFFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: 1rem 0.5rem;
    width: 70%;
  }
}
.swiper_img {
  width: 1.5rem;
  position: absolute;
  top: 40%;
  z-index: 99;
}
.swiper_img_left {
  left: 3%;
}
.swiper_img_right {
  right: 3%;
}
.init-button-disabled {
  opacity: 0;
}
</style>
