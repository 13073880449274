/*eslint-disable*/
export const attrs = [
  // banner区
  'about_banner_image', // banner
  'about_banner_title', // 标题
  'about_banner_desc', // 描述
  'about_banner_image_mobile', // 描述
  'about_understand_desc1_h5', // 描述
  // 了解索菱
  'about_understand_title1',
  'about_understand_desc1',
  'about_understand_leftlogo',
  'about_understand_leftlogo_dsc01',
  'about_understand_leftlogo_dsc02',
  'about_understand_slogan',
  'about_understand_option1',
  'about_understand_unit1',
  'about_understand_option1_desc',
  'about_understand_option2',
  'about_understand_unit2',
  'about_understand_option2_desc',
  'about_understand_option3',
  'about_understand_unit3',
  'about_understand_option3_desc',
  'about_understand_option4',
  'about_understand_unit4',
  'about_understand_option4_desc',
  'about_understand_option5',
  'about_understand_unit5',
  'about_understand_option5_desc',
  'about_understand_option6',
  'about_understand_unit6',
  'about_understand_option6_desc',
  'about_understand_title2',
  'about_understand_desc2',
  'home_culture_title', // 模块标题
  'home_culture_desc', // 模块描述
  'home_culture_right1_title', // 文化选项1（公用）
  'home_culture_right1_desc', // 文化内容1（公用）
  'home_culture_right2_title', // 文化选项2（公用）
  'home_culture_right2_desc', // 文化内容2（公用）
  'home_culture_right3_title', // 文化选项3（公用）
  'home_culture_right3_desc', // 文化内容3（公用）
  'home_culture_right4_title', // 文化选项4（公用）
  'home_culture_right4_desc', // 文化内容4（公用）
  // 企业荣誉
  'about_honor_title',
  'about_honor_desc',
  // 客户及合作伙伴
  'about_partner_title1',
  'about_partner_desc1',
  'about_partner_title2',
  'about_partner_desc2',
  'about_partner_title3',
  'about_partner_desc3',
  // 联系我们
  'about_contact_title',
  'about_contact_desc',
  'about_contact_content_title',
  'about_contact_option1',
  'about_contact_option2',
  'about_contact_option3',
  'about_contact_option4',
  'about_contact_option5',
  'about_contact_option6',
  // 加入索菱
  'about_join_title',
  'about_join_desc',
  'about_join_duty_title',
  'about_join_post_title',
];
