<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="product-main">
      <!--顶部banner-->
      <div class="product-main-banner">
        <div class="product-main-banner-info animate__animated animate__fadeIn animate__delay-0.8s">
          <div class="mc_a1_topbox-info_text1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['product_banner_title']}}</div>
          <div class="mc_a1_topbox-info_text2">{{attrDatas['product_banner_desc']}}</div>
        </div>
        <div class="product-main-banner-img">
          <img v-if="attrDatas['product_banner_image']" :src="attrDatas['product_banner_image']"/>
          <img v-if="!attrDatas['product_banner_image']" src="../../assets/images/product/banner.png"/>
        </div>
        <div class="banner-tab banner-tab-media">
          <div class="banner-tab-main">
            <div
              v-for="tab in tabData"
              :keys="tab.id"
              @click="onChangeTab(tab.childIndex)"
              :class="{'tab-active':tabActive === tab.childIndex}"
              class="banner-tab-content">
              {{tab[`menuName${attributeLang}`]}}
            </div>
          </div>
        </div>
      </div>
      <Overview class="animate__animated animate__fadeIn" :proType="proType" v-show="tabActive === 1"/>
      <ProductDetail class="animate__animated animate__fadeIn" :proType="proType" v-show="tabActive !== 1"/>
    </div>
    <MProduct v-if="isMobile"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "./attrNames";
import Overview from "./components/Overview";
import ProductDetail from "./components/ProductDetail";
import { productsList, saveBrowseHistory } from "@/assets/api/apiService";
import MProduct from './m_product';

export default {
  components: {
    Overview,
    ProductDetail,
    MProduct,
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
      proType: []
    }
  },
  mounted() {
    this.getProductsList();
    this.getSaveBrowseHistory();
  },
  watch: {
    language (val) {
      if (val) this.getSaveBrowseHistory(val);
    }
  },
  methods: {
    getSaveBrowseHistory (language) {
      const local = {zh: 1, en: 2, ja: 3};
      const lang_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);
      saveBrowseHistory({languageType: local[language] || local[lang_str], moduleType: 3});
    },
    onChangeTab (id) {
      this.tabActive = +id;
      this.$router.push({name: 'Product', query: {type: this.tabActive}});
    },
    async getProductsList () {
      let res = await productsList();
      this.proType = res.data;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
