<!--eslint-disable-->
<template>
  <div>
    <div v-if="!isMobile" class="capacity-main">
      <!--顶部banner-->
      <div class="capacity-main-banner">
        <div class="capacity-main-banner-info animate__animated animate__fadeIn animate__delay-0.8s">
          <div class="mc_a1_topbox-info_text1" :class="{'letterSpacing_en': language === 'en'}">{{attrDatas['ability_banner_title']}}</div>
          <div class="mc_a1_topbox-info_text2">{{attrDatas['ability_banner_desc']}}</div>
        </div>
        <div class="capacity-main-banner-img">
          <img v-if="attrDatas['ability_banner_image']" :src="attrDatas['ability_banner_image']"/>
          <img v-if="!attrDatas['ability_banner_image']" src="../../assets/images/capacity/banner.png"/>
        </div>
        <div class="banner-tab banner-tab-media">
          <div class="banner-tab-main">
            <div
              v-for="tab in tabData"
              :keys="tab.id"
              @click="onChangeTab(tab.childIndex)"
              :class="{'tab-active':tabActive === tab.childIndex}"
              class="banner-tab-content">
              {{tab[`menuName${attributeLang}`]}}
            </div>
          </div>
        </div>
      </div>
      <Innovate class="animate__animated animate__fadeInUp animate__delay-0.5s" />
      <Global ref="globalRef" />
      <Quality ref="qualityRef" />
      <RDMag class="animate__animated animate__fadeIn animate__delay-1s" ref="RDRef" />
      <QaMgt class="animate__animated animate__fadeIn animate__delay-1s" ref="qaMgtRef" />
    </div>
    <MCapacity v-if="isMobile"/>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "./attrNames";
import Innovate from './components/Innovate';
import Global from './components/Global';
import Quality from './components/Quality';
import RDMag from './components/RDMag';
import QaMgt from './components/QaMgt';
import MCapacity from './m_capacity/index';
import { saveBrowseHistory } from "@/assets/api/apiService";

export default {
  components: {
    Innovate,
    Global,
    Quality,
    RDMag,
    QaMgt,
    MCapacity
  },
  computed: {
    ...mapState({
      isMobile: state => state.publicData.isMobile,
      menuTree: state => state.publicData.menuTree,
      contentConfig: state => state.publicData.contentConfig,
      language: state => state.publicData.language
    }),
    attributeLang () {
      const local = {zh: 'Cn', en: 'En', ja: 'Jpn'};
      return local[this.language];
    },
    tabData () {
      const res = this.menuTree.find(it => it.webUrl === this.$route.name) || {};
      return res.child;
    },
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  data () {
    return {
      tabActive: +this.$route.query.type || 1,
    }
  },
  mounted () {
    if (!this.isMobile) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.viewRef(this.$route.query.type);
        }, 500)
      })
    }
    this.getSaveBrowseHistory();
  },
  watch: {
    language (val) {
      if (val) this.getSaveBrowseHistory(val);
    }
  },
  methods: {
    getSaveBrowseHistory (language) {
      const local = {zh: 1, en: 2, ja: 3};
      const lang_str = getStorage('soling_language', false) || navigator.language.substring(0, 2);
      saveBrowseHistory({languageType: local[language] || local[lang_str], moduleType: 4});
    },
    onChangeTab (id) {
      this.tabActive = id;
      this.viewRef(id);
    },
    viewRef (type) {
      if (+type === 2) {
        this.$refs.globalRef.scrollIntoView();
      } else if (+type === 3) {
        this.$refs.qualityRef.scrollIntoView();
      } else if (+type === 4) {
        this.$refs.RDRef.scrollIntoView();
      } else if (+type === 5) {
        this.$refs.qaMgtRef.scrollIntoView();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
