/*eslint-disable*/
import {fetch} from "@/libs/request/baseService";

// 分页查询招聘信息
export const pageReportAnnual = (params) => fetch.get('/api/web/pageReportAnnual', {params: params});

// 分页查询所有年报
export const pageRecruitment = (params) => fetch.get('/api/web/pageRecruitment', {params: params});

// 获取所有产品
export const productsList = (params) => fetch.get('/api/web/findProducts', {params: params});

// 上架新闻-资讯分页查询
export const pageNews = (params) => fetch.get('/api/web/pageNews', {params: params});
// 新闻详情
export const detailNews = (params) => fetch.get('/api/web/findNewsById', {params: params});

// 获取官网导航菜单树
export const getMenuTree = (params) => fetch.get('/api/web/getMenuTree', {params: params});

// 查询合作伙伴
export const findPartners = (params) => fetch.get('/api/web/findPartners', {params: params});

// 查询合作伙伴
export const findHonors = (params) => fetch.get('/api/web/findHonors', {params: params});

// 获取所有发展历程
export const findDevelopments = (params) => fetch.get('/api/web/findDevelopments', {params: params});

// 获取证书列表
export const findCertificates = (params) => fetch.get('/api/web/findCertificates', {params: params});

// 获取所有董事会成员
export const findBoardMembers = (params) => fetch.get('/api/web/findBoardMembers', {params: params});

// 根据语言获取所有配置
export const findContentConfig = (params) => fetch.get('/api/web/findContentConfig', {params: params});

// 记录浏览记录
export const saveBrowseHistory = (data) => fetch.post('/api/web/saveBrowseHistory', data);
