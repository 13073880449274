/*eslint-disable*/
import Vue from 'vue';
import Vuex from 'vuex';
import mate from './modules/mate';
import publicData from './modules/publicData';
import getters from './getters';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    mate,
    publicData
  },
  getters
})

export default store
