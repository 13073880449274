<!--eslint-disable-->
<template>
  <div class="innovate-content">
    <div class="innovate-content-title mgt-20">
      <div class="innovate-content-title1">{{attrDatas['ability_innovate_title']}}</div>
      <div class="innovate-content-title2">{{attrDatas['ability_innovate_desc']}}</div>
    </div>
    <div class="innovate-content-main">
      <img v-if="!attrDatas['ability_innovate_image']" src="../../../../assets/images/capacity/innovateImg.png"/>
      <img v-if="attrDatas['ability_innovate_image']" :src="attrDatas['ability_innovate_image']"/>
    </div>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
import { mapState } from "vuex";
import { getAttrValue, getStorage } from "@/libs/tool";
import { attrs } from "../../attrNames";

export default {
  computed: {
    ...mapState({
      language: state => state.publicData.language,
      contentConfig: state => state.publicData.contentConfig
    }),
    attrDatas () {
      if (!this.contentConfig) return {};
      return getAttrValue(this.contentConfig, attrs);
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
