<!--eslint-disable-->
<template>
  <div class="no-data">
    <img src="../assets/images/no_data.png"/>
    <p class="no-data-text">No Data</p>
  </div>
</template>
<!--eslint-disable-->
<script>
/* eslint-disable */
export default {

};
</script>
<style lang="scss" scoped>
.no-data {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  img {
    width: 300px;
  }
  .no-data-text {
    position: absolute;
    top: 65%;
    color: #959595;
    font-size: 14px;
  }
}
</style>
